import { useState } from "react";
import licenseBanner from "../../../_asset/banner/License_banner_mario.png"
import LicenseBannerPopup from "../../banner/license";

export default function LicenseLayout() {
    const [popShow, setPopShow] = useState<boolean>(false)
    
    return (
        <>
        <div className={'jackpot'} onClick={e=> setPopShow(true)}>
            <img src={licenseBanner} className="license-image" alt="" />
        </div>
        
        <LicenseBannerPopup popShow={popShow} setPopShow={setPopShow} />
        </>
    );
}