
import casino_json from "../_asset/json/list_casino.json"

import e1 from "../_asset/casino/img/new/evolution_casino.png"
import mg from "../_asset/casino/img/new/microgaming_casino.png"
import pp from "../_asset/casino/img/new/pragmatic_casino.png"
import ag from "../_asset/casino/img/new/ag_casino.png"
import dg from "../_asset/casino/img/new/dreamgaming_casino.png"
import wm from "../_asset/casino/img/new/wm_casino.png"
import vv from "../_asset/casino/img/new/vivo_casino.png"

import mojos_img from "../_asset/casino/img/new/7-mojos.png"
import absolutegames_img from "../_asset/casino/img/new/absolute.png"
import onetouch_live_img from "../_asset/casino/img/new/onetouch-live.png"
import skywind_img from "../_asset/casino/img/new/Skywind Live.png"
import betgamestv_img from "../_asset/casino/img/new/Betgames.tv.png"
import ezugi_img from "../_asset/casino/img/new/ezugi.png"
import bota_img from "../_asset/casino/img/new/bota_casino1.png"
// import sexybcrt_img from "../_asset/casino/img/new/sexybcrt.png"
import sexybcrt_img from "../_asset/casino/img/new/sexybcrt2.jpg"

import e1_logo from "../_asset/casino/logo/EG.png"
import mg_logo from "../_asset/casino/logo/MicroGaming_logo.png"
import pp_logo from "../_asset/casino/logo/PragmaticPlay_logo.png"
import ag_logo from "../_asset/casino/logo/AG.png"
import dg_logo from "../_asset/casino/logo/DreamGame_logo.png"
import wm_logo from "../_asset/casino/logo/WM Live_logo.png"
import vv_logo from "../_asset/casino/logo/vivo_logo.png"
import bt_logo from "../_asset/casino/logo/bota_logo.png"

import e1_ol from "../_asset/casino/overlay/evolution_overlay.jpg"
import mg_ol from "../_asset/casino/overlay/MicroGaming Plus_overlay.jpg"
import pp_ol from "../_asset/casino/overlay/PragmaticPlay Live_overlay.jpg"
import ag_ol from "../_asset/casino/overlay/Asia Gaming_overlay.jpg"
import dg_ol from "../_asset/casino/overlay/DreamGame_overlay.jpg"
import wm_ol from "../_asset/casino/overlay/WM Live_overlay.jpg"
import vv_ol from "../_asset/casino/overlay/vivo_overlay.jpg"
import betgametv_logo from "../_asset/casino/logo/betgametv.png"
import skywind_logo from "../_asset/casino/logo/skywind.png"
import ezugi_logo from "../_asset/casino/logo/ezugi.png"
import mojos_logo from "../_asset/casino/logo/7mojos.png"
import absolute_logo from "../_asset/casino/logo/absolutegames.png"
import onetouch_logo from "../_asset/casino/logo/onetouch.png"
import sexybcrt_logo from "../_asset/casino/logo/sexybcrt.png"

import mojos_ol from "../_asset/casino/overlay/7Mojos.jpg"
import absolutegames_ol from "../_asset/casino/overlay/absolutegames.jpg"
import onetouch_live_ol from "../_asset/casino/overlay/onetouch.jpg"
import skywind_ol from "../_asset/casino/overlay/skywind.jpg"
import betgamestv_ol from "../_asset/casino/overlay/betgamestv.jpg"
import ezugi_ol from "../_asset/casino/overlay/ezugi.jpg"
import bota_ol from "../_asset/casino/overlay/skywind.jpg"

export type GameCompanyListType = 'evolution' | 'PragmaticPlay Live' | 'MicroGaming Plus' | 'Asia Gaming'
    | 'DreamGame' | 'WM Live' | 'vivo'
     | '7-mojos'
     | 'absolute'
     | 'onetouch'
     | 'Skywind Live'
     | 'Betgames.tv'
     | 'ezugi'
     | 'bota'
     | 'sexybcrt'
export const CasinoVendorListTypeArr = [
    'evolution' , 'PragmaticPlay Live' , 'MicroGaming Plus' , 'Asia Gaming'
    , 'DreamGame' , 'WM Live' , 'vivo'
    , '7-mojos'
    , 'absolute'
    , 'onetouch'
    , 'Skywind Live'
    , 'Betgames.tv'
    , 'ezugi'
    , 'bota'
    , 'sexybcrt'
] 
export interface CasinoImageInterface {
    id: string,
    name: string,
    vendor: GameCompanyListType,
    img: string,
    overlay: string,
    logo: string,
}
export const compL = {
    "evolution": {
        img: e1,
        overlay: e1_ol,
        logo: e1_logo
    },
    "evolution I": {
        img: e1,
        overlay: e1_ol,
        logo: e1_logo
    },
    "PragmaticPlay Live": {
        img: pp,
        overlay: pp_ol,
        logo: pp_logo
    },
    "MicroGaming Plus": {
        img: mg,
        overlay: mg_ol,
        logo: mg_logo
    },
    "Asia Gaming": {
        img: ag,
        overlay: ag_ol,
        logo: ag_logo
    },
    'DreamGame': {
        img: dg,
        overlay: dg_ol,
        logo: dg_logo
    },
    'WM Live': {
        img: wm,
        overlay: wm_ol,
        logo: wm_logo
    },
    'vivo': {
        img: vv,
        overlay: vv_ol,
        logo: vv_logo
    },
    '7-mojos': {
        img: mojos_img,
        overlay: mojos_ol,
        logo: mojos_logo
    },
    'absolute': {
        img: absolutegames_img,
        overlay: absolutegames_ol,
        logo: absolute_logo

    },
    'Skywind Live': {
        img: skywind_img,
        overlay: skywind_ol,
        logo: skywind_logo
    },
    'Betgames.tv': {
        img: betgamestv_img,
        overlay: betgamestv_ol,
        logo: betgametv_logo
    },
    'ezugi': {
        img: ezugi_img,
        overlay: ezugi_ol,
        logo: ezugi_logo
    },
    'bota': {
        img: bota_img,
        overlay: bota_ol,
        logo: bt_logo
    },
    'onetouch': {
        img: onetouch_live_img,
        overlay: onetouch_live_ol,
        logo: onetouch_logo
    },
    'sexybcrt': {
        img: sexybcrt_img,
        overlay: onetouch_live_ol,
        logo: sexybcrt_logo
    },
}
const newJson = []
for(let comp of casino_json){
    //@ts-ignore
    const vendorImageInfo = compL[comp.vendor as GameCompanyListType]
    if(vendorImageInfo){
        const vendorObj: CasinoImageInterface = {
            id: comp.id,
            name: comp.title,
            vendor: comp.vendor as GameCompanyListType,
            img: vendorImageInfo?.img || "",
            overlay: vendorImageInfo?.overlay || "",
            logo: vendorImageInfo?.logo || ""
        }
        newJson.push(vendorObj)
    }
}
export const exportNewCasinoJson = newJson
export const sexyVendor = newJson.find((val)=> val.vendor == 'sexybcrt')

// 아너링크 제외
export const otherCasino = ['Betgames.tv',
    'Skywind Live',
    '7-mojos',
    'absolute',
    'sexybcrt',
    'onetouch',]