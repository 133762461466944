import React from 'react';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import IsDevMode from "../../util/isDevMode";

export default function LobbyRoot() {
    window.document.body.className = 'window-open-lobby'
    window.document.body.style.overflow = 'hidden'
    return (
        <div className={'lobby-root-layout'}>
            <GameLobby/>
        </div>
    );
}



export function GameLobby(){
    const params = useParams();
    const vendor = params.vendor

    const [location] = useSearchParams()
    const uid = location.get('uid')

    let openUrl: string = "";
    if(IsDevMode()){
        openUrl = "https://mario001.com"
    }
    openUrl += `/api/game/games/link/honor?uid=${uid}&vendor=${vendor}`
    if(vendor == 'PragmaticPlay J'){
        openUrl += `/api/game/games/link/pragmatic?uid=${uid}`
    }
    return (
        <iframe className={"lobby-iframe"}
                src={openUrl}
                frameBorder="0"></iframe>
    )
}