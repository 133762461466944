import React, {useContext, useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import {DeviceWidthContext, UserContext} from "../../../apps/_root";
import api from "../../../util/api";
import useInterval from "../../../hooks/useInterval";
import {with_comma} from "../../../util/comma";
import ReplayModalIframe from "../../game/replay_modal";

interface RealtimeJackpotInterface {
    "nickname": string,
    "game_title": string,
    "win": number,
    "odds": number,
    "link": string
}
function RealTimeJackpot() {
    const [jackpotList, setJackpotList] = useState<RealtimeJackpotInterface[]>()
    const [replayShow, SetReplayShow] = useState<boolean>(false)
    const [replayLink, setReplayLink] = useState<string>("")

    const [siwperViewCount, SetSiwperViewCount] = useState(2)
    const [swiperAutoPlay, SetSwiperAutoPlay] = useState<boolean>(false)
    const {deviceWidth} = useContext(DeviceWidthContext)
    const {User, LoginFailed} = useContext(UserContext)

    function updateList(){
        api(`/api/game/jackpots/`)
            .then(res=> res.json())
            .then((data: RealtimeJackpotInterface[]) => {
                setJackpotList(data)
            })
            .catch(e=> setJackpotList([]))
    }
    useEffect(()=> {
        updateList()
    }, [])
    useInterval(()=> {
        updateList()
    }, 60000)

    useEffect(()=> {
        // console.log("event : ", deviceWidth)
        if(deviceWidth < 768){
            SetSiwperViewCount(1)
            SetSwiperAutoPlay(true)
        }else{
            SetSiwperViewCount(4)
            SetSwiperAutoPlay(false)
        }
    }, [deviceWidth])

    function openReplay(link: string){
        if(User?.is_authenticated){
            SetReplayShow(true)
            setReplayLink(link)
        }else{
            LoginFailed()
        }
    }
    return (
        <div className={'randing-list event'}>
            <header>
                <p>금주의잭팟 TOP10 : </p>
            </header>
            <main>
                {
                    jackpotList
                        ? <Swiper
                                className={'withdraw-swiper'}
                                direction={'vertical'}
                                slidesPerView={siwperViewCount}
                                spaceBetween={5}
                                loop={ jackpotList.length > 4 ? true : false }
                                loopedSlides={3}
                                autoplay={{
                                    delay: 2000
                                }}
                                modules={[Autoplay]}
                            >
                                {
                                    jackpotList?.map((jackpot, index)=>
                                        <SwiperSlide key={index}>
                                            <div className={'list-item'}>
                                                <div className={'item'}>{jackpot.nickname}</div>
                                                <div className={'item'}>{jackpot.game_title}</div>
                                                <div className={'item'}>{with_comma(jackpot.win)}</div>
                                                <div className={'item'}>
                                                    X{with_comma(jackpot.odds)}배
                                                    <span onClick={e=> openReplay(jackpot.link)}>R</span>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                                {
                                    jackpotList.length > 5 && jackpotList.length < 9
                                        ? jackpotList.map((jackpot, index)=>
                                            <SwiperSlide key={index}>
                                                <div className={'list-item'}>
                                                    <div className={'item'}>{jackpot.nickname}</div>
                                                    <div className={'item'}>{jackpot.game_title}</div>
                                                    <div className={'item'}>{with_comma(jackpot.win)}</div>
                                                    <div className={'item'}>
                                                        X{with_comma(jackpot.odds)}배
                                                        <span onClick={e=> openReplay(jackpot.link)}>R</span>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                        : <></>
                                }
                            </Swiper>
                        : <></>
                }
            </main>

            <ReplayModalIframe SetReplayShow={SetReplayShow}
                               replayShow={replayShow}
                               link={replayLink}
            />
        </div>
    );
}

export default RealTimeJackpot;