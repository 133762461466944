import React, {useContext, useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from "swiper";
import {DeviceWidthContext} from "../../../apps/_root";
import api from "../../../util/api";
import useInterval from "../../../hooks/useInterval";
import {with_comma} from "../../../util/comma";
import moment from "moment";

interface RealTimeDepositListInterface {
    "kind": string,
    "amount": number,
    "user": string,
    "processed_at": string
}
function RealtimeWithdrawal() {
    const [depositList ,setDepositList] = useState<RealTimeDepositListInterface[]>()
    const [siwperViewCount, SetSiwperViewCount] = useState(2)
    const {deviceWidth} = useContext(DeviceWidthContext)

    function updateList(){
        api(`/api/wallet/live_depositwithdrawals/`)
            .then(res=> res.json())
            .then((data: RealTimeDepositListInterface[]) => {
                setDepositList(data)
            })
            .catch(e=> setDepositList([]))
    }
    useEffect(()=> {
        updateList()
    }, [])
    useInterval(()=> {
        updateList()
    }, 60000)
    useEffect(()=> {
        // console.log("withdrawal : ", deviceWidth)
        if(deviceWidth < 768){
            SetSiwperViewCount(3)
        }else{
            SetSiwperViewCount(4)
        }
    }, [deviceWidth])
    return (
        <div className={'randing-list withdraw'}>
            <header>
                <p>실시간 출금현황 : </p>
            </header>
            <main>
                {
                    depositList
                        ? <Swiper
                            className={'withdraw-swiper'}
                            direction={'vertical'}
                            slidesPerView={siwperViewCount}
                            spaceBetween={5}
                            loop={true}
                            autoplay={{
                                delay: 2000
                            }}
                            modules={[Autoplay]}
                        >
                            {
                                depositList.map((deposit, index)=>
                                    <SwiperSlide key={index}>
                                        <div className={'list-item'}>
                                            <div className={'item'}>{deposit.kind}</div>
                                            <div className={'item'}>{deposit.user}</div>
                                            <div className={'item'}>{with_comma(deposit.amount)} 원</div>
                                            <div className={'item'}>{moment(deposit.processed_at).format('MM-DD')}</div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                            {
                                depositList.length > 5 && depositList.length < 9
                                    ? depositList.map((deposit, index)=>
                                        <SwiperSlide key={index}>
                                            <div className={'list-item'}>
                                                <div className={'item'}>{deposit.kind}</div>
                                                <div className={'item'}>{deposit.user}</div>
                                                <div className={'item'}>{with_comma(deposit.amount)} 원</div>
                                                <div className={'item'}>{moment(deposit.processed_at).format('MM-DD')}</div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                    : <></>
                            }
                        </Swiper>
                        : <></>

                }
            </main>
        </div>
    );
}

export default RealtimeWithdrawal;