import React, {useContext, useEffect, useRef, useState} from 'react';

// nav icon
import deposit from "../../_asset/image/nav/deposit.png"
import withdraw from "../../_asset/image/nav/withdraw.png"
import customer from "../../_asset/image/nav/customer.png"
import event from "../../_asset/image/nav/event.png"
import notice from "../../_asset/image/nav/notice.png"
import mypage from "../../_asset/image/nav/mypage.png"

import icon_casino from "../../_asset/image/nav/casino.png"
import icon_slot from "../../_asset/image/nav/slot.png"
import icon_reel from "../../_asset/image/nav/reel.png"
import icon_minigame from "../../_asset/image/nav/minigame.png"
import icon_deposit from "../../_asset/image/nav/deposit.png"
import icon_withdraw from "../../_asset/image/nav/withdraw.png"
import icon_notice from "../../_asset/image/nav/notice.png"
import icon_mypage from "../../_asset/image/nav/mypage.png"

import { PageModalContext } from "../../apps/providers";
import {DeviceWidthContext, UserContext} from "../../apps/_root";

// nav obj
export interface navListInterface {
    img: string,
    text: string,
}
export const navList = {
    game: [
        {
            img: icon_casino,
            text: "카지노"
        },
        {
            img: icon_slot,
            text: "슬롯머신"
        },
        {
            img: icon_reel,
            text: "릴게임"
        },
        {
            img: icon_minigame,
            text: "미니게임"
        },
    ],
    mypage: [
        {
            img: icon_deposit,
            text: "입금신청"
        },
        {
            img: icon_withdraw,
            text: "출금신청"
        },
        {
            img: icon_notice,
            text: "공지사항"
        },
        {
            img: icon_mypage,
            text: "마이페이지"
        },
    ]
}

function HeaderNavigation() {
    const {LoginFailed, UserAuthCheck} = useContext(UserContext)
    const {SetPageModalShow, SetMenuType, SetPageName} = useContext(PageModalContext)

    return (
        <>
            <MobileNav UserAuthCheck={UserAuthCheck}
                       LoginFailed={LoginFailed}/>
            <nav className={'header-nav'}>
                <ul className={'menu-animation'}>
                    {
                        navList.game.map((nav, index)=> (
                            <li key={index}
                                onClick={e=>{
                                    UserAuthCheck(()=> {
                                        SetMenuType('game')
                                        SetPageName(nav.text)
                                        SetPageModalShow(true)
                                    })
                                }}>
                                <p>
                                    <img src={nav.img}
                                         className={'icon'}
                                         alt={nav.text}/>
                                    {nav.text}
                                </p>
                            </li>
                        ))
                    }
                </ul>

                {/*logo*/}

                <ul className={'menu-animation'}>
                    {
                        navList.mypage.map((nav, index)=> (
                            <li key={index}
                                onClick={e=>{
                                    UserAuthCheck(()=> {
                                        SetMenuType('board')
                                        SetPageName(nav.text)
                                        SetPageModalShow(true)
                                    })
                                }}>
                                <p>
                                    <img src={nav.img}
                                         className={'icon'}
                                         alt={nav.text}/>
                                    {nav.text}
                                </p>
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </>
    );
}

export default HeaderNavigation;

function MobileNav({UserAuthCheck, LoginFailed}: {UserAuthCheck: Function, LoginFailed: Function}){
    const {deviceWidth} = useContext(DeviceWidthContext)
    const [navShow, setNavShow] = useState<boolean>(false)
    const {SetPageModalShow, SetMenuType, SetPageName} = useContext(PageModalContext)

    function handleClose(){
        setNavShow(false)
    }
    useEffect(()=> {
        if(deviceWidth >= 1060){
            handleClose()
        }
    }, [deviceWidth])
    return (
        <div className={'mobile-menu-layout'}>
            <div className={`mobile-ham ${ navShow ? "active" : "" }`}
                 onClick={e=>setNavShow(!navShow)}
            >
                <span className={'ham-bar'}></span>
                <span className={'ham-bar'}></span>
                <span className={'ham-bar'}></span>
            </div>
            <div className={`mobile-modal ${ navShow ? "active" : "" }`}
                 onClick={e=>setNavShow(false)}
            ></div>
            <nav className={`mobile-nav ${ navShow ? "active" : "" }`}>
                <ul>
                    {
                        navList.game.map((nav, index)=> (
                            <li key={index}
                                onClick={e=> {
                                    UserAuthCheck(()=> {
                                        SetMenuType('board')
                                        SetPageName(nav.text)
                                        SetPageModalShow(true)
                                    })
                                }}>
                                <p>
                                    <img src={nav.img}
                                         className={'icon'}
                                         alt={nav.text}/>
                                    {nav.text}
                                </p>
                            </li>
                        ))
                    }
                    {
                        navList.mypage.map((nav, index)=> (
                            <li key={index}
                                onClick={e=> {
                                    UserAuthCheck(()=> {
                                        SetMenuType('board')
                                        SetPageName(nav.text)
                                        SetPageModalShow(true)
                                    })
                                }}>
                                <p>
                                    <img src={nav.img}
                                         className={'icon'}
                                         alt={nav.text}/>
                                    {nav.text}
                                </p>
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </div>
    )
}