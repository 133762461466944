import { useEffect } from "react";
import useSWR from "swr";

export interface PrivateUser {
    uuid: string;
    username: string;
    nickname: string;
    is_authenticated: boolean;
    is_anonymous: boolean;
    money: number;
    point: number;
}
export const PrivateUserDefault = {
    uuid: '',
    username: '',
    nickname: '',
    is_authenticated: false,
    is_anonymous: false,
    money: 0,
    point: 0
}

async function fetcher(url: string) {
    return fetch(url).then((res) => res.json());
}

export default function useUser({ next = false, loginRequired = false } = {}) {
    const {
        data: user,
        mutate: mutateUser,
        isLoading,
    } = useSWR<PrivateUser>("/api/user/whoami", fetcher, {
        refreshWhenHidden: true,
        refreshWhenOffline: true,
        revalidateOnFocus: true,
        refreshInterval: 1000,
        revalidateIfStale: false,
    });

    useEffect(() => {
        // 로딩 중
        if (!user) {
            return;
        }

        // 로그인 필요 + 익명(비로그인) 상태
        if (loginRequired && user.is_anonymous) {
            alert("로그인 후 이용가능합니다.");
        }
    }, [user, next, loginRequired]);

    return { user, mutateUser, isLoading };
}
