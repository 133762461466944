
import slot_json from "../_asset/json/list_slot.json"

// import img_pragmatic from "../_asset/slot/list/pragmatic.jpg"
import img_pragmatic from "../_asset/slot/gif/pragmatic.gif"
// import img_pragmatic from "../_asset/image/slot/g1.png"
import img_pragmatic_J from "../_asset/slot/gif/pragmatic J.gif"
import img_cq9 from "../_asset/slot/list/cq9.jpg"
import img_netent from "../_asset/slot/gif/netent.gif"
import img_redtiger from "../_asset/slot/list/redtiger.jpg"
import img_playngo from "../_asset/slot/list/playngo.jpg"
import img_booongo from "../_asset/slot/list/booongo.jpg"
import img_playson from "../_asset/slot/gif/playson.gif"
import img_nolimit from "../_asset/slot/list/nolimit.jpg"
import img_1x2gaming from "../_asset/slot/list/1x2gaming.jpg"
import img_blueprintgaming from "../_asset/slot/gif/blueprint.gif"
import img_dragoonsoft from "../_asset/slot/list/dragoonsoft.jpg"
import img_dreamtech from "../_asset/slot/list/dreamtech.jpg"
import img_elkstudios from "../_asset/slot/list/elkstudios.jpg"
import img_evoplay from "../_asset/slot/gif/evoplay.gif"
import img_gameart from "../_asset/slot/list/gameart.jpg"
import img_habanero from "../_asset/slot/list/habanero.jpg"
import img_microgaming from "../_asset/slot/list/microgaming.jpg"
import img_mobilots from "../_asset/slot/list/mobilots.jpg"
import img_playpears from "../_asset/slot/list/playpears.jpg"
import img_playstar from "../_asset/slot/list/playstar.jpg"
import img_pgsoft from "../_asset/slot/gif/pgsoft.gif"
import img_relaxgaming from "../_asset/slot/list/relaxgaming.jpg"
import img_thunderkick from "../_asset/slot/list/thunderkick.jpg"
import img_wazdan from "../_asset/slot/gif/wazdan.gif"
import img_BigTimeGaming from "../_asset/slot/gif/BigTimeGaming.gif"
import img_greentube from "../_asset/slot/gif/greentube.gif"
import img_amatic from "../_asset/slot/gif/amatic.gif"
import img_popok from "../_asset/slot/gif/popok.gif"
import img_kagaming from "../_asset/slot/gif/kagaming.gif"

import hover_pragmatic from "../_asset/slot/hover/pragmatic.mp4"
import hover_pragmatic_J from "../_asset/slot/hover/pragmatic J.mp4"
// import hover_pragmatic_J from "../_asset/slot/hover/pragmatic J.mp4"
import hover_netent from "../_asset/slot/hover/netent.mp4"
import hover_pgsoft from "../_asset/slot/hover/pg soft.mp4"
import hover_playson from "../_asset/slot/hover/playson.mp4"
import hover_blueprint from "../_asset/slot/hover/blueprint.mp4"
import hover_wazdan from "../_asset/slot/hover/wazdan.mp4"
import hover_evoplay from "../_asset/slot/hover/evoplay.mp4"
import hover_BigTimeGaming from "../_asset/slot/hover/BigTimeGaming.mp4"
import hover_greentube from "../_asset/slot/hover/greentube.mp4"
import hover_amatic from "../_asset/slot/hover/amatic.mp4"
import hover_popok from "../_asset/slot/hover/popok.mp4"
import hover_kagaming from "../_asset/slot/hover/kagaming.mp4"

// import logo_pragmatic from "../_asset/slot/logo/Pragmatic Play.png"
// import logo_pragmatic_B from "../_asset/slot/logo/Pragmatic Play.png"
import logo_pragmatic from "../_asset/slot/logo/pragmatic X.png"
import logo_pragmatic_B from "../_asset/slot/logo/pragmatic J.png"
import logo_cq9 from "../_asset/slot/logo/CQ9.png"
import logo_netent from "../_asset/slot/logo/netent.png"
import logo_redtiger from "../_asset/slot/logo/Red Tiger Gaming.png"
import logo_playngo from "../_asset/slot/logo/Play’n GO.png"
import logo_booongo from "../_asset/slot/logo/BNG.png"
import logo_playson from "../_asset/slot/logo/Playson.png"
import logo_nolimit from "../_asset/slot/logo/nolimit-city.png"
import logo_pgsoft from "../_asset/slot/logo/pgsoft.png"
import logo_habanero from "../_asset/slot/logo/habanero.png"
import logo_playstar from "../_asset/slot/logo/playstar.png"
import logo_thunderkick from "../_asset/slot/logo/thunderkick.png"
import logo_mobilots from "../_asset/slot/logo/mobilots.png"
import logo_blueprintgaming from "../_asset/slot/logo/blueprintgaming.png"
import logo_playpears from "../_asset/slot/logo/playpears.png"
import logo_dragoonsoft from "../_asset/slot/logo/dragoonsoft.png"
import logo_gameart from "../_asset/slot/logo/gameart.png"
import logo_relaxgaming from "../_asset/slot/logo/relaxgaming.png"
import logo_wazdan from "../_asset/slot/logo/wazdan.png"
import logo_evoplay from "../_asset/slot/logo/evoplay.png"
import logo_1x2gaming from "../_asset/slot/logo/1x2gaming.png"
import logo_dreamtech from "../_asset/slot/logo/dreamtech.png"
import logo_elkstudios from "../_asset/slot/logo/elkstudios.png"
import logo_microgaming from "../_asset/slot/logo/microgaming.png"
import logo_BigTimeGaming from "../_asset/slot/logo/BigTimeGaming.png"
import logo_greentube from "../_asset/slot/logo/greentube.png"
import logo_amatic from "../_asset/slot/logo/amatic.png"
import logo_popok from "../_asset/slot/logo/popok.png"
import logo_kagaming from "../_asset/slot/logo/kagaming.png"

export type GameCompanyListType = 'PragmaticPlay' | 'PragmaticPlay J' | 'CQ9' | 'netent' | 'redtiger'
    | 'playngo' | 'Booongo' | 'Playson' | 'Nolimit City'
    | '1X2 Gaming' | 'Blueprint Gaming' | 'Dragoon Soft' | 'dreamtech' | 'Elk Studios'
    | 'EVOPLAY' | 'GameArt' | 'Habanero' | 'micro-gaming' | 'Mobilots'
    | 'PlayPearls' | 'PlayStar' | 'PG Soft' | 'Relax Gaming' | 'Thunderkick' | 'Wazdan'
    | 'BigTimeGaming' | 'greentube' | 'amatic' | 'popok' | "kagaming"
export const SlotVendorListTypeArr = [
    'PragmaticPlay' , 'PragmaticPlay J' , 'CQ9' , 'netent' , 'redtiger'
    , 'playngo' , 'Booongo' , 'Playson' , 'Nolimit City'
    , '1X2 Gaming' , 'Blueprint Gaming' , 'Dragoon Soft' , 'dreamtech' , 'Elk Studios'
    , 'EVOPLAY' , 'GameArt' , 'Habanero' , 'micro-gaming' , 'Mobilots'
    , 'PlayPearls' , 'PlayStar' , 'PG Soft' , 'Relax Gaming' , 'Thunderkick' , 'Wazdan'
    , 'BigTimeGaming' , 'greentube' , 'amatic' , 'popok', 'kagaming'
]
export interface SlotImageInterface {
    id: string,
    vendor: string,
    img: string,
    hover?: string,
    name?: string,
    logo: string,
}
export const compL = {
    "PragmaticPlay": {
        img: img_pragmatic,
        hover: hover_pragmatic,
        logo: logo_pragmatic,
    },
    "PragmaticPlay J": {
        img: img_pragmatic_J,
        hover: hover_pragmatic_J,
        logo: logo_pragmatic_B,
    },
    "CQ9": {
        img: img_cq9,
        logo: logo_cq9,
    },
    'PG Soft': {
        img: img_pgsoft,
        hover: hover_pgsoft,
        logo: logo_pgsoft
    },
    'Booongo': {
        img: img_booongo,
        logo: logo_booongo,
    },
    'Habanero': {
        img: img_habanero,
        logo: logo_habanero
    },
    'PlayStar': {
        img: img_playstar,
        logo: logo_playstar
    },
    'Thunderkick': {
        img: img_thunderkick,
        logo: logo_thunderkick
    },
    'Mobilots': {
        img: img_mobilots,
        logo: logo_mobilots
    },
    // ---------------------------------------- 상위 8
    'Nolimit City': {
        img: img_nolimit,
        logo: logo_nolimit,
    },
    'Blueprint Gaming': {
        img: img_blueprintgaming,
        hover: hover_blueprint,
        logo: logo_blueprintgaming
    },
    'PlayPearls': {
        img: img_playpears,
        logo: logo_playpears
    },
    'Dragoon Soft': {
        img: img_dragoonsoft,
        logo: logo_dragoonsoft
    },
    'Playson': {
        img: img_playson,
        hover: hover_playson,
        logo: logo_playson,
    },
    'GameArt': {
        img: img_gameart,
        logo: logo_gameart
    },
    'Relax Gaming': {
        img: img_relaxgaming,
        logo: logo_relaxgaming
    },
    'Wazdan': {
        img: img_wazdan,
        hover: hover_wazdan,
        logo: logo_wazdan
    },
    'EVOPLAY': {
        img: img_evoplay,
        hover: hover_evoplay,
        logo: logo_evoplay
    },
    "netent": {
        img: img_netent,
        hover: hover_netent,
        logo: logo_netent,
    },
    "redtiger": {
        img: img_redtiger,
        logo: logo_redtiger,
    },
    'playngo': {
        img: img_playngo,
        logo: logo_playngo,
    },
    '1X2 Gaming': {
        img: img_1x2gaming,
        logo: logo_1x2gaming
    },
    'dreamtech': {
        img: img_dreamtech,
        logo: logo_dreamtech
    },
    'Elk Studios': {
        img: img_elkstudios,
        logo: logo_elkstudios
    },
    'micro-gaming': {
        img: img_microgaming,
        logo: logo_microgaming
    },
    'BigTimeGaming': {
        img: img_BigTimeGaming,
        hover: hover_BigTimeGaming,
        logo: logo_BigTimeGaming
    },
    'greentube': {
        img: img_greentube,
        hover: hover_greentube,
        logo: logo_greentube
    },
    'amatic': {
        img: img_amatic,
        hover: hover_amatic,
        logo: logo_amatic
    },
    'popok': {
        img: img_popok,
        hover: hover_popok,
        logo: logo_popok
    },
    'kagaming': {
        img: img_kagaming,
        hover: hover_kagaming,
        logo: logo_kagaming
    },
}
const newJson = []
for(let comp of slot_json){
    const vendorImageInfo = compL[comp.vendor as GameCompanyListType]
    const vendorObj: SlotImageInterface = {
        id: comp.id,
        name: comp.title,
        vendor: comp.vendor as GameCompanyListType,
        img: vendorImageInfo.img || "",
        //@ts-ignore
        hover: vendorImageInfo?.hover || "",
        //@ts-ignore
        logo: vendorImageInfo?.logo || "",
    }
    newJson.push(vendorObj)
}
export const exportNewSlotJson = newJson

export const blockedSlotList = ['popok', 'micro-gaming', 'Nolimit City']