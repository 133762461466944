import React, {useContext, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faMicrophone} from '@fortawesome/free-solid-svg-icons';
import Btn from "../_custom/button";
import { FormRow } from '../_custom/form_row';
import {with_comma} from "../../util/comma";
import {PageModalContext} from "../../apps/providers";
import {DialogSetContext, UserContext} from "../../apps/_root";
import swal from "sweetalert";


function Withdraw() {
    const {SetPageModalShow} = useContext(PageModalContext)
    const {dialogSet} = useContext(DialogSetContext)
    const {User} = useContext(UserContext)
    return (
        <div className={'request-layout'}>
            <header className={'request-header'}>
                <Btn color={'yellow'}>
                    <FontAwesomeIcon icon={faMicrophone}/>
                </Btn>
                <p>입금 전 반드시 계좌문의 후 입금 해주시기 바랍니다!</p>
                {/*<span>입금계좌문의 <FontAwesomeIcon icon={faArrowRight}/></span>*/}
            </header>

            <main className={'request-main'}>
                <FormRow>
                    <FormRow.Name>출금은행</FormRow.Name>
                    <FormRow.Input
                        type={'text'}
                        placeholder={"고객센터문의"}
                        readOnly
                    ></FormRow.Input>
                </FormRow>
                <FormRow>
                    <FormRow.Name>출금 예금주</FormRow.Name>
                    <FormRow.Input
                        type={'text'}
                        placeholder={"고객센터문의"}
                        readOnly
                    ></FormRow.Input>
                </FormRow>
                <FormRow>
                    <FormRow.Name>계좌번호</FormRow.Name>
                    <FormRow.Input
                        type={'text'}
                        placeholder={"고객센터문의"}
                        readOnly
                    ></FormRow.Input>
                </FormRow>
                <FormRow>
                    <FormRow.Name>출금비밀번호</FormRow.Name>
                    <FormRow.Input
                        type={'text'}
                        placeholder={"고객센터문의"}
                        readOnly
                    ></FormRow.Input>
                </FormRow>
                <AmountLayout/>
            </main>

            <div className={'request-btn-area'}>
                <Btn color={'orange'}
                     onClick={e=> {
                         swal({
                             text: '고객센터로 문의바랍니다.',
                             icon: 'warning'
                         } )
                     }}>출금신청</Btn>
                <Btn color={'gray'} onClick={e=> SetPageModalShow(false)}>신청취소</Btn>
            </div>
        </div>
    );
}

export default Withdraw;

function AmountLayout(){
    const [amount, setAmount] = useState<number>(0)

    function addAmount(add: number){
        setAmount(amount + add)
    }
    return (
        <>
            <FormRow>
                <FormRow.Name>출금금액</FormRow.Name>
                <FormRow.Input
                    type={'text'}
                    placeholder={"최소출금 30,000원 부터"}
                    value={with_comma(amount)}
                    readOnly
                ></FormRow.Input>
            </FormRow>
            <FormRow>
                <div className={'request-amount-btn-area'}>
                    <Btn color={'gray'} onClick={e=> addAmount(30000)} >3만</Btn>
                    <Btn color={'gray'} onClick={e=> addAmount(50000)} >5만</Btn>
                    <Btn color={'gray'} onClick={e=> addAmount(100000)} >10만</Btn>
                    <Btn color={'gray'} onClick={e=> addAmount(500000)} >50만</Btn>
                    <Btn color={'gray'} onClick={e=> addAmount(1000000)} >100만</Btn>
                    <Btn color={'carrot'} onClick={e=> setAmount(0)} >정정</Btn>
                </div>
            </FormRow>
        </>
    )
}