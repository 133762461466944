import liceseBanner_pc from "../../_asset/banner/License_pc.png"
import liceseBanner_mo from "../../_asset/banner/License_mo.png"
import { isMobile } from "react-device-detect";
import ModalContainer from "../_common/_Modal_layout";

export default function LicenseBannerPopup({popShow, setPopShow}: {popShow: boolean, setPopShow: Function}){
    return (        
        <ModalContainer show={popShow}
                        setShow={setPopShow}
                        closeBtn={true}
                        centered={true}
                        className={'license-banner'}
        >
            <img src={isMobile ? liceseBanner_mo : liceseBanner_pc} alt="" />
        </ModalContainer>
    )
}