import React from 'react';
import partner from "../../_asset/image/etc/partner.png"

function Footer() {
    return (
        <footer id={'footer'}>
            <div className={'partner'}>
                <img src={partner} alt="partner"/>
            </div>
            <div className={'copylight'}>
                <p>ⓒ COPYRIGHT MARIO CASINO 2023 ALL RIGHTS RESERVED.</p>
            </div>
        </footer>
    );
}

export default Footer;