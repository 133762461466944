import React from 'react';

import Comp from "../../_asset/image/logo/Comp.png"
import diamond from "../../_asset/image/logo/diamond.png"
import crown from "../../_asset/image/logo/crown.png"
import items from "../../_asset/image/logo/items.png"
import star from "../../_asset/image/logo/star.png"
// import Circus from "../../_asset/image/logo/Circus.png"
import Mario from "../../_asset/image/logo/Mario.png"
import Casino from "../../_asset/image/logo/Casino.png"

export default function Logo({link}: {link: boolean}){
    return (
        <div className={`logo ${link ? '--pointer' : ''}`} onClick={e=> {
            if(link){
                window.location.href = '/'
            }
        }}>
            <img src={Comp}     alt="Comp"    className={'Comp'}/>
            <img src={diamond}  alt="diamond" className={'diamond'}/>
            <img src={crown}    alt="crown"   className={'crown'}/>
            <img src={items}    alt="items"   className={'items'}/>
            <img src={star}     alt="star"    className={'star'}/>
            <img src={Mario}    alt="Mario"  className={'Circus'}/>
            <img src={Casino}   alt="Casino"  className={'Casino'}/>
        </div>
    )
}
Logo.defaultProps = {
    link: false
}