import React, {useContext, useState} from 'react';
import Btn from "../_custom/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faPlay} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "../../apps/_root";
import AudioPlayer from "../_custom/audio_player";

function Overlay({vendor, uid}: {vendor: string; uid: string}) {
    const {UserAuth, LoginFailed} = useContext(UserContext)
    const [audioPlay, setAudioPlay] = useState<boolean>(false)
    return (
        <div className={'overlay'}
             onMouseOver={e=> setAudioPlay(true)}
             onMouseOut={e=> setAudioPlay(false)}
             onClick={e=> {
                 if(UserAuth){
                     // window.open(`/lobby/${vendor}?uid=${uid}`
                     //     , `${vendor}_${uid}`
                     //     , "width=1200, height=600"
                     // )
                     if(vendor == 'PragmaticPlay J'){
                         vendor = "pragmatic"
                     }
                     window.open(`/gameLobby/${vendor}/?uid=${uid}&vendor=${vendor}`,
                         `${vendor}_${uid}`, `width=1200 height=600`)
                 }else{
                     LoginFailed()
                 }
             }}>
            <Btn color={'yellow'} >
                <FontAwesomeIcon className={'play'} icon={faPlay}/>
                <FontAwesomeIcon className={'arrow'} icon={faArrowRight}/>
            </Btn>
            <AudioPlayer type={"company"} play={audioPlay}/>
        </div>
    );
}

export default Overlay;