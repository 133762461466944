
export interface dataJsonInterface {
    title: string;
    id: string;
    vendor: string;
}

export default function getVendorInfo(vendor: string, arr: dataJsonInterface[]){
    let returnObj: dataJsonInterface = {
        title: "",
        id: "",
        vendor: "",
    }
    if(arr.length){
        for(let val of arr){
            if(val.vendor == vendor){
                returnObj =  val as dataJsonInterface
                break;
            }
        }
    }

    return returnObj
}