import React, {ReactNode, useContext, useState} from 'react';
import ModalContainer from "../_common/_Modal_layout";
import Btn from "../_custom/button";
import { FormRow } from '../_custom/form_row';
import {DialogSetContext} from "../../apps/_root";
import swal from "sweetalert";


function RegisterModal({registerShow, setRegisterShow}:
                           {registerShow: boolean; setRegisterShow: Function}) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const {dialogSet} = useContext(DialogSetContext)
    function customerMessage(){
        swal({text: "회원가입을 원할시 고객센터로 문의바랍니다", icon: 'warning'})
    }
    async function handleAPIError(res: Response) {
        setSubmitting(false);
        const err: any = await res.json();
        alert(err);
    }
    return (
        <ModalContainer show={registerShow}
                        setShow={setRegisterShow}
                        className={'register-pop'}
                        title={'회원가입'}
        >
            <main>
                <div className={'inner-layout'}>
                    <header>
                        <div className={'header-bg'}></div>
                        <p>언제나 노력하는 <b>마리오 카지노</b> 되겠습니다!</p>
                    </header>
                    <form action="" >
                        <div className={'form-inner'}>
                            <section>
                                <FormRow>
                                    <FormRow.Name>아이디</FormRow.Name>
                                    <FormRow.Input type="text"
                                           name={'username'}
                                           placeholder={`6자이상 영문, 숫자만 가능`}/>
                                </FormRow>
                                <FormRow>
                                    <FormRow.Name>이름</FormRow.Name>
                                    <FormRow.Input type="text"
                                           name={'name'}
                                           placeholder={`2자이상 한글,영문만 가능(예금주와 동일해야 함)`}/>
                                </FormRow>
                                <FormRow>
                                    <FormRow.Name>닉네임</FormRow.Name>
                                    <FormRow.Input type="text"
                                           name={'nickname'}
                                           placeholder={`2자이상 한글,영문 및 숫자 가능`}/>
                                </FormRow>
                                <FormRow>
                                    <FormRow.Name>비밀번호</FormRow.Name>
                                    <FormRow.Input type="password"
                                           name={'password'}
                                           placeholder={``}/>
                                </FormRow>
                                <FormRow>
                                    <FormRow.Name>비밀번호 확인</FormRow.Name>
                                    <FormRow.Input type="password"
                                           name={'password_confirm'}
                                           placeholder={``}/>
                                </FormRow>
                            </section>
                            <section>
                                <FormRow>
                                    <FormRow.Name>전화번호</FormRow.Name>
                                    <div className={'form-btn-input'}>
                                        <input type="text"
                                               name={'phone_number'}
                                               placeholder={`'_'없이 숫자 만 입력`}/>
                                        <Btn color={'green'}
                                             onClick={customerMessage}
                                        >인증받기</Btn>
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <FormRow.Name>인증번호</FormRow.Name>
                                    <div className={'form-btn-input'}>
                                        <input type="text"
                                               name={'phone_number'}
                                               placeholder={`문자 미수신 시 관리자문의`}/>
                                        <Btn color={'green'}
                                             onClick={customerMessage}
                                        >인증확인</Btn>
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <FormRow.Name>은행명</FormRow.Name>
                                    <select name="bank_name" >
                                        <option value="">선택하세요.</option>
                                    </select>
                                </FormRow>
                                <FormRow>
                                    <FormRow.Name>계좌번호</FormRow.Name>
                                    <FormRow.Input type="text"
                                           name={'bank_number'}
                                           placeholder={`'_'없이 숫자 만 입력`}/>
                                </FormRow>
                                <FormRow>
                                    <FormRow.Name>예금주</FormRow.Name>
                                    <FormRow.Input type="text"
                                           name={'bank_owner'}
                                           placeholder={`2자이상 한글, 영문만 가능(예금주는 수정불가/고객센터문의)`}/>
                                </FormRow>
                            </section>
                        </div>

                        <div className={'btn-area'}>
                            <Btn color={'yellow'}
                                 onClick={customerMessage}
                            >회원가입</Btn>
                            <Btn color={'gray'}
                                 onClick={e=> setRegisterShow(false)}>취소</Btn>
                        </div>
                    </form>
                </div>
            </main>
        </ModalContainer>
    );
}

export default RegisterModal;
