import React, {useContext, useEffect, useState} from 'react';
import Btn from "../../_custom/button";
import api from "../../../util/api";
import {noticeApiInterface, noticeListInterface} from "../../page/notice_list";
import useInterval from "../../../hooks/useInterval";
import moment from "moment";
import {NoticeModalContext} from "../../../apps/providers";
import {UserContext} from "../../../apps/_root";

function NoticeSlideShow() {
    const {SetNoticeModalOpen} = useContext(NoticeModalContext)
    const [noticeList, setNoticeList] = useState<noticeListInterface[]>()

    function getNoticeList(){
        api(`/api/site/notices/?page=1`)
            .then(res=> res.json())
            .then((data: noticeApiInterface) => {
                setNoticeList(data.notices)
            })
            .catch(e=> setNoticeList([]))
    }

    useEffect(()=> {
        getNoticeList()
    }, [])
    useInterval(()=> {
        getNoticeList()
    }, 60000)
    return (
        <div className={'notice-slide-show'}>
            <Btn color={'yellow'}>
                <span>공지사항</span>
            </Btn>
            <div className={'notice-list'}>
                <ul>
                    {
                        noticeList?.map((notice, index)=>
                            <NoticeSlideItem key={index} notice={notice} SetNoticeModalOpen={SetNoticeModalOpen}/>
                        )
                    }
                    {
                        noticeList?.map((notice, index)=>
                            <NoticeSlideItem key={index} notice={notice} SetNoticeModalOpen={SetNoticeModalOpen}/>
                        )
                    }
                </ul>
            </div>
        </div>
    );
}

export default NoticeSlideShow;

function NoticeSlideItem({notice, SetNoticeModalOpen}:
                             {
                                 notice: noticeListInterface;
                                 SetNoticeModalOpen: Function;
                             }){
    const {UserAuth, LoginFailed} = useContext(UserContext)
    return (
        <li onClick={e=> {
            if(UserAuth){
                SetNoticeModalOpen(notice.id)
            }else{
                LoginFailed()
            }
        }}>
            <div>{notice.title}</div>
            {/*<div>{moment(notice.written_at).format('MM-DD')}</div>*/}
        </li>
    )
}