import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {getCookie, setCookie} from "../../util/cookie";
import Btn from "../_custom/button";
import Logo from "./Logo";
import {noticeListInterface} from "./CookieSetComponent";

function PopNotification({popupNumber, popupContent, showLength, setShowLength}:
                             {popupNumber: number, popupContent: noticeListInterface, showLength: number, setShowLength: any}) {
    const ad_cookie = getCookie(`ad_${popupNumber}`)
    const [pnShow, setPnShow] = useState<boolean>(ad_cookie == "1" ? true : false)
    const [bodyShow, setBodyShow] = useState<"" | "fade-out-hide-active">("")

    function handleClose(){
        setBodyShow("fade-out-hide-active")
        setTimeout(()=> {
            setPnShow(false)
            setShowLength(showLength - 1)
        }, 250)
    }
    function handleCookieDeleteClose(){
        handleClose()

        const dayAgo = new Date(new Date().getTime() + (60000 * 60 * 24))
        // const dayAgo = new Date(new Date().getTime() + 5000)
        setCookie(`ad_${popupNumber}`, "0", {
            path: '/', expires: dayAgo
        })
    }

    return (
        pnShow ?
            <div className={`pn-body ${bodyShow} ad_${popupNumber}`}>
                <span className={"pn-close"} onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </span>

                <header className={'pn-title'}>
                    <Logo/>
                </header>

                <main className={"pn-content"} dangerouslySetInnerHTML={{__html:popupContent.content}}></main>

                <div className="btn-area">
                    <Btn color={"yellow"} onClick={handleCookieDeleteClose}>오늘 하루 닫기</Btn>
                </div>
            </div>
        : <></>
    );
}

export default PopNotification;