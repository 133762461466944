import React, {useEffect, useState} from 'react';
import {getCookie, setCookie} from "../../util/cookie";
import PopNotification from "./PopNotification";
import api from "../../util/api";
import spEventImag from "../../_asset/banner/pop_event.jpg"
import Btn from "../_custom/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export interface noticeListInterface {
    id: number,
    title: string,
    content: string,
    written_at: string,
}
export interface noticeApiInterface {
    total: number,
    notices: noticeListInterface[]
}

function CookieSetComponent() {
    const [noticeList, setNoticeList] = useState<noticeListInterface[]>([])


    useEffect(()=> {
        api(`/api/site/notices/?kind=popup`)
            .then(res => res.json())
            .then((data: noticeApiInterface) => {
                setNoticeList(data.notices)
            })
    }, [])

    return (
        <>
            {/* <EventPopupDialog/> */}
            {
                noticeList.length ? <ComponentParent cookieNameList={noticeList}/> : <></>
            }
        </>
    )
}

export default CookieSetComponent;

function ComponentParent({cookieNameList}: {cookieNameList: noticeListInterface[]}){
    const [dialogAreaShow, setDialogAreaShow] = useState<boolean>(true)
    const [showLength, setShowLength] = useState<number>(0)

    function checkAdPopupCookiesCount(){
        let count: number = 0
        for(let value of cookieNameList){
            if(!getCookie(`ad_${value.id}`)){
                setCookie(`ad_${value.id}`, `1`)
            }

            if(getCookie(`ad_${value.id}`) == `1`){
                count++;
            }
        }
        setShowLength(count)
    }
    useEffect(()=> {
        if(showLength < 1) {
            setDialogAreaShow(false)
        }else{
            setDialogAreaShow(true)
        }
    }, [showLength])
    useEffect(()=> {
        checkAdPopupCookiesCount()
    }, [])
    return (
        <>
            {
                dialogAreaShow ?
                    <div className={"pn-dialog"} >
                        {
                            cookieNameList.map((list, index)=> (
                                <PopNotification key={index}
                                                 popupNumber={list.id}
                                                 popupContent={list}
                                                 showLength={showLength}
                                                 setShowLength={setShowLength}/>
                            ))
                        }
                    </div>
                    :<></>
            }
        </>
    )
}

function EventPopupDialog(){
    const [show, setShow] = useState<boolean>(setSpecialEventPopState)

    function setSpecialEventPopState(){
        const eventCookie = getCookie('sp_event_3')
        if(!eventCookie){
            const dayAgo = new Date(new Date().getTime() + (60000 * 60 * 2))
            setCookie(`sp_event_3`, "1", {
                path: '/', expires: dayAgo
            })
            return true;
        }else{
            return false;
        }
    }
    function handleCookieDeleteClose(){
        setShow(false)
        window.document.body.style.overflow = 'visible'

        const dayAgo = new Date(new Date().getTime() + (60000 * 60 * 2))
        setCookie(`sp_event_3`, "0", {
            path: '/', expires: dayAgo
        })
    }

    useEffect(()=> {
        const eventCookie = getCookie('sp_event_3')
        if(eventCookie == `1`){
            setShow(true)
            window.document.body.style.overflow = 'hidden'
        }else{
            setShow(false)
            window.document.body.style.overflow = 'visible'
        }

    }, [])

    return (
        show
            ? <div className={'pop-event-dialog'}>
                <div className={'pop-event-body'}>
                    <div className={'close-event'} onClick={e=> {
                        setShow(false)
                        window.document.body.style.overflow = 'visible'
                    }}>
                        <FontAwesomeIcon icon={faXmark} size={`2x`}/>
                    </div>
                    <img src={spEventImag} alt="sp"/>
                    <div className="btn-area">
                        <Btn color={"yellow"} onClick={handleCookieDeleteClose}>2시간 안보이기</Btn>
                    </div>
                </div>
            </div>
            : <></>

    )
}
