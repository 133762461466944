import React from 'react';
import ReactDOM from 'react-dom/client';
// css module
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import '../src/_asset/css/circus.css';

// pages App
import Home from "./apps/page";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import RootLayout from "./apps/_root";
import LobbyRoot, {GameLobby} from "./apps/lobby/lobby";
import GameWindowRoot, {GameWindow} from "./apps/window/game";


const route = createBrowserRouter([
    {
        path: "/",
        element: <RootLayout/>,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/lobby",
                element: <LobbyRoot />,
                children: [
                    { path: ":vendor/", element: <GameLobby/> }
                ]
            },
            {
                path: "/gameLobby",
                element: <GameWindowRoot />,
                children: [
                    { path: ":vendor/", element: <GameWindow/> }
                ]
            }
        ]
    }
])


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<RouterProvider router={route} />);
