import React, {HTMLAttributes, useContext, useEffect, useRef, useState} from 'react';
import Logo from "./Logo";
import HeaderNavigation from "./_Navigation";
import {PageModalContext} from "../../apps/providers";
import Btn from "../_custom/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faCoins, faEnvelope, faParking, faUserGear, faUserTie} from "@fortawesome/free-solid-svg-icons";
import {PrivateUser} from "../../hooks/useUser";
import level from "../../_asset/image/etc/level.png";
import {jsx} from "@emotion/react";
import {DialogSetContext, UserContext} from "../../apps/_root";
import {with_comma} from "../../util/comma";
import api from "../../util/api";
import swal from "sweetalert";

function RootHeader() {
    return (
        <header id={'root-header'}>
            <Logo link />

            <HeaderTop/>
            <HeaderNavigation/>
        </header>
    );
}

export default RootHeader;


function HeaderTop(){
    const { UserAuth } = useContext(UserContext)
    return (
        <div className={'header-top'}>
            {
                UserAuth
                    ? <UserAuthInfo/>
                    : <UserNonLogin/>
            }
        </div>
    )
}

function UserNonLogin(){
    const {SetLoginShow, SetRegisterShow} = useContext(UserContext)
    return (
        <div className={'user-nonLogin-layout'}>
            <Btn color={'yellow'}
                    onClick={SetLoginShow}>
                <span>로그인</span>
            </Btn>
            <Btn color={'orange'}
                    onClick={SetRegisterShow}>
                <span>회원가입</span>
            </Btn>
        </div>
    )
}

function UserAuthInfo(){
    const {User, Logout} = useContext(UserContext)
    const {SetPageModalShow, SetMenuType, SetPageName} = useContext(PageModalContext)
    const dropDownRef = useRef<HTMLDivElement>(null)
    const [dropDown, setDropDown] = useState<boolean>(false)

    function PointExchangeCash(){
        api(`/api/user/exchange-point`, {method: 'POST'})
            .catch(e=> swal({
                text: '포인트 전환 오류가 발생했습니다.',
                icon: 'error'
            } ))
    }

    useEffect(() => {
        // 클릭 이벤트 리스너 추가
        const handleClick = (event: MouseEvent) => {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
                // 클릭한 위치가 타겟 요소의 내부가 아닌 경우 처리
                if(dropDown){
                    setDropDown(false);
                }
            }
        };

        // 이벤트 리스너 등록
        document.addEventListener('click', handleClick);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <>
            <div className={'user-login-layout'} >
                {/*<Btn color={`yellow users ${dropDown ? "active" : ""}`}*/}
                {/*     className={'fromPC'}*/}
                {/*     onClick={e=> setDropDown(!dropDown)}>*/}
                {/*    <FontAwesomeIcon icon={faUserGear} className={'user-info'}/>*/}
                {/*    <FontAwesomeIcon icon={faChevronDown} className={'arrow-down'}/>*/}
                {/*</Btn>*/}
                {/*<Btn color={'yellow message fromPC'}>*/}
                {/*    <FontAwesomeIcon icon={faEnvelope}/>*/}
                {/*</Btn>*/}
                <div className={'user-state'}>
                    <p>
                        <div className={'icon'}>
                            <FontAwesomeIcon icon={faUserTie}/>
                        </div>
                        {User.nickname}
                    </p>
                    <p>
                        <div className={'icon'}>
                            <FontAwesomeIcon icon={faCoins}/>
                        </div>
                        <b>{with_comma(User.money)}</b> 원
                    </p>
                </div>
                {/*<Btn color={'green'}*/}
                {/*     className={'fromMO'}*/}
                {/*     onClick={e=> setDropDown(!dropDown)}*/}
                {/*>내정보</Btn>*/}
                <Btn color={'gray'} onClick={e=> Logout()}>로그아웃</Btn>

                {/*<div ref={dropDownRef}*/}
                {/*     className={`user-drop-layout ${dropDown ? "active" : ""}`} >*/}
                {/*    <DropUserInfo User={User} />*/}
                {/*</div>*/}
            </div>
            {
                User.point > 0
                    ? <>
                        <div className={'user-point'}>
                            <div className={'icon'}>
                                <FontAwesomeIcon icon={faParking}/>
                            </div>
                            <p>{with_comma(User.point)} <b>P</b></p>

                            <Btn color={'orange'} onClick={PointExchangeCash}>포인트 전환</Btn>
                        </div>
                    </>
                    : <div className={'user-point empty'}></div>
            }
        </>
    )
}

function DropUserInfo({User}: { User: PrivateUser; }) {
    const {dialogSet} = useContext(DialogSetContext)
    function PointExchangeCash(){
        api(`/api/user/exchange-point`, {method: 'POST'})
            .catch(e=> swal({
                text: '포인트 전환 오류가 발생했습니다.',
                icon: 'error'
            } ))
    }
    return (
        <>
            <section className={'top'}>
                <div className={'info-layout'}>
                    <div className={'user-row'}>
                        <div className={'icon'}>
                            <FontAwesomeIcon icon={faUserTie}/>
                        </div>
                        <p>아이디:</p>
                        <p>{User.nickname} <b>님</b></p>
                    </div>
                    <div className={'user-row money'}>
                        <div className={'icon'}>
                            <FontAwesomeIcon icon={faCoins}/>
                        </div>
                        <p>보유머니:</p>
                        <p>{with_comma(User.money)} <b>원</b></p>
                    </div>
                    {
                        User.point > 0
                            ? <div className={'user-row point'}>
                                <div className={'icon'}>
                                    <FontAwesomeIcon icon={faParking}/>
                                </div>
                                <p>포인트:</p>
                                <p>{with_comma(User.point)} <b>P</b></p>
                            </div>
                            : <></>
                    }
                </div>
                {/*<div className={'exp-layout'}>*/}
                {/*    <img src={level} alt="level"/>*/}
                {/*    <div className={'exp-bar'}>*/}
                {/*        <div className={'bar'}>*/}
                {/*            <span>0%</span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </section>
            {
                User.point > 0
                    ? <section className={'bot'}>
                        <Btn color={'orange'} onClick={PointExchangeCash}>포인트 전환</Btn>
                    </section>
                    : <></>
            }
        </>
    )
}