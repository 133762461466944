import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnglesLeft, faAnglesRight, faArrowRight, faPlay} from "@fortawesome/free-solid-svg-icons";
import {Swiper, SwiperSlide} from "swiper/react";
import api from "../../util/api";
import {Autoplay, Navigation} from "swiper";
import {Player} from "@lottiefiles/react-lottie-player";
import loading from "../../_asset/lottie/loading.json"
import Btn from "../_custom/button";
import Overlay from "../game/overlay";
import {DeviceWidthContext, UserContext} from "../../apps/_root";

function PopulerGames() {
    return (
        <div className={'populer-games'}>
            <header>
                <div className={'inner'}>
                    <button className={'populer-arrow left'}>
                        <FontAwesomeIcon icon={faAnglesLeft}/>
                    </button>
                    <p className={'title'}>마리오 인기게임</p>
                    <button className={'populer-arrow right'}>
                        <FontAwesomeIcon icon={faAnglesRight}/>
                    </button>
                </div>
            </header>
            <GameMain/>
        </div>
    );
}

export default PopulerGames;

interface vendorGameListInterface {
    uid: string,
    title: string,
    vendor: string,
    thumbnail: string
}
function GameMain(){
    const [gameList, setGameList] = useState<vendorGameListInterface[]>()
    const [slidePerView, SetSlidePerView] = useState<number>(7)
    const {deviceWidth} = useContext(DeviceWidthContext)
    const {UserAuth, LoginFailed} = useContext(UserContext)

    useEffect(()=> {
        // console.log(deviceWidth)
        if(deviceWidth < 512) {
            SetSlidePerView(2)
        }else if(deviceWidth < 768) {
            SetSlidePerView(4)
        }else if(deviceWidth < 1060) {
            SetSlidePerView(5)
        }else{
            SetSlidePerView(7)
        }
    }, [deviceWidth])
    useEffect(()=> {
        // const apiUrl = `/api/game/games/pragmatic/`;
        const apiUrl = `/api/game/games/honor/?vendor=PragmaticPlay`;
        api(apiUrl)
            .then((res)=> res.json())
            .then((data: vendorGameListInterface[]) => setGameList(data))
            .catch(e=> setGameList([]))
    },[])
    return (
        <main>
            <Swiper
                className={'populer-game-swiper'}
                slidesPerView={slidePerView}
                slidesPerGroup={1}
                spaceBetween={16}
                loop={true}

                speed={600}
                autoplay={{
                    delay: 1200
                }}
                navigation={{
                    prevEl: ".populer-arrow.left",
                    nextEl: ".populer-arrow.right",
                }}
                modules={[Autoplay, Navigation]}
            >
                {
                    gameList && gameList.length > 0
                        ? gameList.map((game, index)=> {
                            if(index < 14){
                                return (
                                    <SwiperSlide key={index}>
                                        <div className={'populer-game-item game-item'}>
                                            <span>인기</span>
                                            <img src={game.thumbnail} className={'game-image'} />
                                            <p className={'title'}>{game.title}</p>

                                            <Overlay vendor={'PragmaticPlay'} uid={game.uid}/>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                        })
                        : [...Array(slidePerView)].map((_, index)=>
                            <SwiperSlide key={index} >
                                <PopulerLoading />
                            </SwiperSlide>
                         )

                }
            </Swiper>
        </main>
    )
}

function PopulerLoading(){
    return (
        <div>
            <Player src={loading}
                    autoplay
                    loop
                    style={{ width: "18rem", height: "18rem" }}
            ></Player>
        </div>
    )
}