import React from 'react';

interface CustomButtonInterface extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    color: string;
}
const Btn = React.forwardRef<HTMLButtonElement, CustomButtonInterface>(
    (props, ref)=> {
        const { color="", ...rest } = props;

        rest.className = rest.className ?? ""
        rest.className += ` btn-${color}`

        return <a { ...rest } role={'button'}></a>
    }
)

export default Btn;

