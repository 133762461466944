import React, {useContext, useEffect, useState} from 'react';
import crown from "../../_asset/image/logo/crown.png";
import {blockedSlotList, exportNewSlotJson, SlotVendorListTypeArr} from "../../data/gameData_slot";
import {exportNewCasinoJson, otherCasino} from "../../data/gameData_casino";
import gameItembg from "../../_asset/image/game list/gameItembg.png"
import gameItembgGlow from "../../_asset/image/game list/gameItembg_glow.png"
import {Swiper, SwiperSlide, useSwiper, useSwiperSlide} from "swiper/react";
import arrows from "../../_asset/image/etc/arrows.png"
import {Navigation} from "swiper";
import {PageModalContext} from "../../apps/providers";
import Btn from "../_custom/button";
import {DialogSetContext, UserContext} from "../../apps/_root";
import AudioPlayer from "../_custom/audio_player";
import swal from "sweetalert"

// exportNewSlotJson
function GameList() {
    const {dialogSet} = useContext(DialogSetContext)
    const [title, setTitle] = useState<string>('카지노')

    function commingSoon(){
        swal({
            text: '현재 업데이트 예정인 게임입니다.',
            icon: 'warning'
        } )
    }
    function BlockDot(){
        return (
            <div className={'block-dot'}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        )
    }
    return (
        <div className={'game-list-layout'}>
            <header>
                <div className={'tab'}>
                    <div className={`tab-btn`}>
                        <div className={`left  ${title == '슬롯' ? "active" : "disabled"}`}>
                            <b>슬롯</b>
                            <span className={'arrow'}>▼</span>
                            <BlockDot/>
                        </div>
                    </div>
                    <div className={`tab-btn`}>
                        <div className={`right ${title == '카지노' ? "active" : "disabled"}`}>
                            <b>카지노</b>
                            <span className={'arrow'}>▼</span>
                            <BlockDot/>
                        </div>
                    </div>
                    <div className={'tab-btn'}>
                        <div className={'disabled'}
                           onClick={commingSoon} >
                            <b>미니게임</b>
                            <span className={'arrow'}>▼</span>
                            <BlockDot/>
                        </div>
                    </div>
                    <div className={'tab-btn'}>
                        <div className={'disabled'}
                           onClick={commingSoon} >
                            <b>릴게임</b>
                            <span className={'arrow'}>▼</span>
                            <BlockDot/>
                        </div>
                    </div>
                </div>
            </header>
            <Swiper
                className={'game-list-ca-sl'}
                style={{ paddingBottom: "4rem" }}
                spaceBetween={40}
                autoHeight={true}
                navigation={{
                    prevEl: ".search > .left, .tab .left",
                    nextEl: ".search > .right, .tab .right",
                }}
                modules={[Navigation]}
            >
                <SwiperSlide>
                    <SlotMain setTitle={setTitle}/>
                </SwiperSlide>
                <SwiperSlide>
                    <CasinoMain setTitle={setTitle}/>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default GameList;

function SlotMain({setTitle}: {setTitle: Function}){
    const swiperSlide = useSwiperSlide();

    useEffect(()=> {
        if(swiperSlide.isActive){
            setTitle('슬롯')
        }
    }, [swiperSlide.isActive])
    return <SlotList/>

}
export function SlotList(){
    return (
        <main className={'vendor-list slot'}>
            {
                exportNewSlotJson.map((game, index)=> (
                    <Items key={index} game={game}/>
                ))
            }
        </main>
    )
}
function CasinoMain({setTitle}: {setTitle: Function}){
    const swiperSlide = useSwiperSlide();

    useEffect(()=> {
        if(swiperSlide.isActive){
            setTitle('카지노')
        }
    }, [swiperSlide.isActive])
    return <CasinoList/>
}
export function CasinoList(){
    return (
        <main className={'vendor-list casino'}>
            {
                exportNewCasinoJson.map((game, index)=> (
                    <Items key={index} game={game}/>
                ))
            }
        </main>
    )
}


function Items({game}: {game: any}){
    const {UserAuthCheck} = useContext(UserContext)
    const {SetMenuType, SetVendor, SetPageName, SetPageModalShow} = useContext(PageModalContext)
    const [audioPlay, setAudioPlay] = useState<boolean>(false)
    return (
        <div className={'game-list-item as'}
             title={game.name}
             onMouseOver={e=> setAudioPlay(true)}
             onMouseOut={e=> setAudioPlay(false)}
             onClick={e => {
                 UserAuthCheck(()=> {
                     if(blockedSlotList.includes(game.vendor)){
                         swal({text: "점검중 입니다.", icon: "warning"})
                     }else{
                         // SetMenuType('vendor')
                         // SetPageName(game.name)
                         // SetVendor(game.vendor)
                         // SetPageModalShow(true)
                         if(otherCasino.includes(game.vendor) || SlotVendorListTypeArr.includes(game.vendor)){
                             // 슬롯, 로비 없는 카지노
                             SetMenuType('vendor')
                             SetPageName(game.name)
                             SetVendor(game.vendor)
                             SetPageModalShow(true)
                         }else{
                             // 로비 有
                             window.open(`/gameLobby/${game.vendor}/?uid=${game.uid}&vendor=${game.vendor}`,
                                 `${game.vendor}_${game.uid}`, `width=1200 height=600`)
                         }
                     }
                 })
             }}
        >
            <div className={'frame'}>
                <img src={gameItembg} className={"bg"} alt="gameItembg"/>
                <img src={gameItembgGlow} className={"overlay glow"} alt="glow"/>
                <img src={game.img} className={"overlay game"} alt="glow"/>

                <div className={'game-info'}>
                    <img src={game.logo} className={'game-logo'} alt={game.name}/>
                </div>
            </div>
            <Btn color={'yellow'} >게임시작</Btn>
            <p className={'game-title'}>{game.name}</p>
            <AudioPlayer type={"company"} play={audioPlay}/>
        </div>
    )
}