import React from 'react';
import RootHeader from "../component/_common/_header";
import Banner from "../component/_common/_Banner";
import NoticeSlideShow from "../component/_randing/resize_component/notice_slide_show";
import RealtimeWithdrawal from "../component/_randing/resize_component/realtime_withdrawal";
import RealTimeJackpot from "../component/_randing/resize_component/jackpot_list";
import Jackpot from "../component/_randing/resize_component/jackpot";
import PopulerGames from "../component/_randing/populer_games";
import Footer from "../component/_common/footer";
import GameList from "../component/_randing/game_list";
import Providers from './providers';
import CookieSetComponent from "../component/_common/CookieSetComponent";
import LicenseLayout from '../component/_randing/resize_component/license_layout';


export default function Home() {

    return (
        <>
            <Providers>
                <CookieSetComponent/>
                <RootHeader/>
                <main id={'main-container'} >
                    <Banner/>
                    <DeviceChangeComp/>
                    <PopulerGames/>
                    <GameList/>
                </main>
            </Providers>
            <Footer/>
        </>
    );
}
function DeviceChangeComp(){
    return (
        <div className={'randing-device-change'}>
            <NoticeSlideShow/>
            {/* <Jackpot/> */}
            <LicenseLayout/>
            <RealtimeWithdrawal/>
            <RealTimeJackpot/>
        </div>
    )
}
