import React, {createContext, ReactNode, useContext, useEffect, useRef, useState} from 'react';
import ModalContainer from "../_common/_Modal_layout";
import Modal from "react-bootstrap/Modal";
import crown from "../../_asset/image/logo/crown.png";
import {navList, navListInterface} from "../_common/_Navigation";
import {PageModalContext} from "../../apps/providers";
import {CasinoList, SlotList} from "../_randing/game_list";
import comming_soon_circus from "../../_asset/media/comming_soon_circus.mp4"
import {CasinoVendorListTypeArr, otherCasino} from "../../data/gameData_casino";
import {SlotVendorListTypeArr} from "../../data/gameData_slot";
import api from "../../util/api";
import { Player } from '@lottiefiles/react-lottie-player';
import lottie_loading from "../../_asset/lottie/loading.json"
import slot_json from "../../_asset/json/list_slot.json";
import casino_json from "../../_asset/json/list_casino.json";
import getVendorInfo from "../../util/getVendorInfo";
import NoticeList from "./notice_list";
import Deposit from "./deposit";
import Withdraw from "./withdraw";
import {FormRow} from "../_custom/form_row";
import Overlay from "../game/overlay";
import mario_repair from "../../_asset/image/etc/mario_repair.webp"
import {UserContext} from "../../apps/_root";
import swal from "sweetalert"
import {isMobile} from "react-device-detect";

export type modalPageMenuType = "game" | "vendor" | "board"
export const HandleCloseContext = createContext({
    handleClose: ()=> {}
})
export default function PageModal({pageModalShow, setPageModalShow, menuType, pageName, vendor}:
                                      {
                                          pageModalShow: boolean;
                                          setPageModalShow: Function;
                                          menuType: modalPageMenuType;
                                          pageName: string;
                                          vendor: string;
                                      }) {
    const [loadState, setLoadState] = useState<boolean>(false)

    function handleClose(){
        setPageModalShow(false)
    }
    useEffect(()=> {
        setLoadState(false)
        setTimeout(()=> {
            setLoadState(true)
        }, 300)
    }, [menuType, pageName, vendor])
    return (
        <ModalContainer
            show={pageModalShow}
            setShow={setPageModalShow}
            className={`menu-popup ${menuType}`}
        >
            <aside>
                <AsideMenu modalAsideType={menuType} pageName={pageName}/>
            </aside>
            <main>
                <Modal.Header className={"modal-title"}>
                    <div className={'title-inner'}>{pageName}</div>
                </Modal.Header>
                <div className={`page-modal-main ${ loadState ? "" : 'load-active' }`}>
                    <HandleCloseContext.Provider value={{handleClose}}>
                        <MainList menuType={menuType}
                                  pageName={pageName}
                                  vendor={vendor}
                        />
                    </HandleCloseContext.Provider>
                </div>
            </main>
        </ModalContainer>
    );
}

function MainList({menuType, pageName, vendor}:
                      {
                          menuType: modalPageMenuType;
                          pageName: string;
                          vendor: string;
                      }){
    const [loadPage, setLoadPage] = useState<ReactNode>(<CommingSoon/>)

    useEffect(()=> {
        if(menuType == 'vendor'){
            if(otherCasino.includes(vendor) || SlotVendorListTypeArr.includes(vendor)){
                // 슬롯, 로비 없는 카지노
                setLoadPage(<PageModalGameList vendor={vendor}/>)
            }else{
                // 로비 有
                setLoadPage(<IframeLobby vendor={vendor}/>)
            }
        }else{
            switch(pageName){
                default:
                    setLoadPage(<CommingSoon/>)
                    break;
                case "카지노" :
                    setLoadPage(<CasinoList />)
                    break;
                case "슬롯머신" :
                    setLoadPage(<SlotList />)
                    break;
                case "공지사항" :
                    setLoadPage(<NoticeList />)
                    break;
                case "입금신청" :
                    setLoadPage(<Deposit />)
                    break;
                case "출금신청" :
                    setLoadPage(<Withdraw />)
                    break;
            }
        }
    }, [pageName])

    return (
        <div>
            {loadPage}
        </div>
    )
}

function AsideMenu({modalAsideType, pageName}: { modalAsideType: modalPageMenuType; pageName: string }) {
    const {SetPageName, SetMenuType} = useContext(PageModalContext)
    const [asideTitle, setAsideTitle] = useState<string>('메뉴보기')
    const [list, setList] = useState<navListInterface[]>([])

    useEffect(()=> {
        switch(modalAsideType){
            case "game":
                setAsideTitle('메뉴보기')
                setList(navList.game as [])
                break;
            case "vendor":
                setAsideTitle('게임리스트')
                setList([])
                break;
            case "board":
                setAsideTitle('메뉴보기')
                setList(navList.mypage as [])
                break;
        }
    }, [modalAsideType])

    return (
        <>
            <header className={'aside-header'}>
                <img src={crown} alt="crown" className={'crown'}/>
                <p>{asideTitle}</p>
            </header>
            <ul className={'aside-menu menu-animation'}>
                {
                    list.map((nav, index)=> (
                        <li key={index}
                            className={ pageName == nav.text ? "active" : "" }
                            onClick={e=>{
                                SetPageName(nav.text)
                                SetMenuType(modalAsideType)
                            }}>
                            <p>
                                <img src={nav.img}
                                     className={'icon'}
                                     alt={nav.text}/>
                                {nav.text}
                            </p>
                        </li>
                    ))
                }
            </ul>
        </>
    )
}

function CommingSoon(){
    return (
        <div className={'full-size --d-center'}>
            {/*<video src={comming_soon_circus}*/}
            {/*       autoPlay*/}
            {/*       loop*/}
            {/*       className={'full-size'}*/}
            {/*></video>*/}
            <div className={'mario-repair'}>
                <img src={mario_repair} alt=""/>
                <p>준비중 입니다.</p>
            </div>
        </div>
    )
}

interface vendorGameListInterface {
    uid: string,
    title: string,
    vendor: string,
    thumbnail: string
}
function PageModalGameList({vendor}: {vendor: string}){
    const [loadingState, setLoadingState] = useState<boolean>(true)
    const [gameList, setGameList] = useState<vendorGameListInterface[]>()

    const {handleClose} = useContext(HandleCloseContext)

    useEffect(()=> {
        let apiUrl: string;
        switch(vendor){
            case "PragmaticPlay J" :
                apiUrl = `/api/game/games/pragmatic/`;
                break;
            default:
                apiUrl = `/api/game/games/honor/?vendor=${vendor}`;
                break;
        }
        api(apiUrl)
            .then((res)=> res.json())
            .then((data: vendorGameListInterface[]) => setGameList(data))
            .catch((res: Response)=> {
                swal({
                    text: "현재 이용 불가능 한 게임사 입니다.",
                    icon: "error",
                }).then(function(){
                    handleClose()
                })
            })
    }, [])
    return (
        <main className={'game-list'}>
            {
                gameList?.map((game, index)=> (
                    <GameItems key={index} game={game} vendor={vendor} index={index}/>
                ))
            }
        </main>
    )
}

function GameItems({game, vendor, index}: {game: vendorGameListInterface; vendor: string; index: number}){
    const itemsRef = useRef<HTMLDivElement>(null)
    const [loadState, setLoadState] = useState<boolean>(false)
    const [imgLoadState, setImgLoadState] = useState<boolean>(false)

    useEffect(()=> {
        if(!isMobile){
            setTimeout(()=> {
                setLoadState(true)
            }, index * 50)
        }else{
            setLoadState(true)
        }
    }, [])
    return (
        <div className={ `game-item ${loadState ? "show" : ""}` }
             ref={itemsRef}>
            <ImageHolder src={game.thumbnail}
                         setImgLoadState={setImgLoadState}
                         title={game.title} />
            <p className={'game-name'}>{game.title}</p>
            {
                imgLoadState
                    ? <Overlay vendor={vendor} uid={game.uid}/>
                    : <></>
            }
        </div>
    )
}

interface imgInterface extends React.ImgHTMLAttributes<HTMLImageElement> {
    setImgLoadState: Function
}
const ImageHolder = React.forwardRef<HTMLImageElement, imgInterface>(
    (props, ref)=> {
        const imgRef = useRef<HTMLImageElement>(null)
        const holderRef = useRef<HTMLDivElement>(null)

        function loadImage(){
            if(imgRef.current && holderRef.current){
                imgRef.current.style.opacity = "1"
                holderRef.current.style.display = "none"
                props.setImgLoadState(true)
            }
        }
        return (
            <div className={'img-placeholder-cover'}>
                <img {...props}
                     onLoad={loadImage}
                     ref={imgRef}/>
                <div className={'img-placeholder'}
                     ref={holderRef}>
                    <Player src={lottie_loading} loop autoplay />
                </div>
            </div>
        )
    }
)

interface openLink {
    link: string
}
function IframeLobby({vendor}: {vendor: string}){
    const {UserAuth} = useContext(UserContext)

    const [iframeLink, setIframeLink] = useState<string>()
    const [loadingState, setLoadingState] = useState<boolean>(true)
    const iframeRef = useRef<HTMLIFrameElement>(null)

    const vendorSum = slot_json && casino_json
    const vendorInfo = getVendorInfo(vendor, vendorSum);
    let responseAPIUrl = `/api/game/lobbies/link/honor?vendor=${vendor}&id=${vendorInfo.id}`
    if(vendor == 'evolution I') {
        responseAPIUrl = `/api/game/lobbies/link/oracle?vendor=${vendor}&id=${vendorInfo.id}`
    }

    function getHonorLink(){
        api(responseAPIUrl)
            .then(res => res.json())
            .then((data: openLink) => {
                if(data?.link){
                    setIframeLink(data.link)
                }
            })
    }
    function iframeOnLoad(){
        setLoadingState(false)
        if(iframeRef.current){
            console.log(iframeRef.current.src)
        }
    }
    useEffect(()=> {
        // console.log(UserAuth)
    }, [UserAuth])
    useEffect(()=> {
        getHonorLink()
    }, [])
    return (
        <main className={'iframe-modal-main'}>
            {
                !UserAuth
                    ? <div className={'iframe-logout'}>로그아웃 되었습니다</div>
                    : <>
                        {
                            loadingState
                                ? <div className={'iframe-loading-animation'}>
                                    <Player src={lottie_loading} loop autoplay/>
                                </div>
                                : <></>
                        }
                        {
                            iframeLink
                                ? <iframe className={'lobby-honor-link-iframe'}
                                          ref={iframeRef}
                                          onLoad={iframeOnLoad}
                                          src={iframeLink}
                                          frameBorder={0}></iframe>
                                : <div className={'iframe-loading-empty'}
                                       onLoad={iframeOnLoad}></div>
                        }
                    </>
            }

        </main>
    )
}

