import { Player } from '@lottiefiles/react-lottie-player';
import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useParams, useSearchParams} from 'react-router-dom';
import loading from "../../_asset/lottie/loading.json"
import {UserContext} from "../_root";
import IsDevMode from "../../util/isDevMode";
import {isMobile} from "react-device-detect";
import api, {ApiErrorHandler} from "../../util/api";
import slot_json from "../../_asset/json/list_slot.json";
import casino_json from "../../_asset/json/list_casino.json";
import getVendorInfo from "../../util/getVendorInfo";
import {otherCasino} from "../../data/gameData_casino";
import {SlotVendorListTypeArr} from "../../data/gameData_slot";
import swal from "sweetalert";

export default function GameWindowRoot(){
    document.body.className = 'game-window-body'
    return (
        <div className={'game-window-layout'}>
            <Outlet/>
        </div>
    )
}

interface getDataInterface {
    uid: string,
    vendor: string
}
export function GameWindow() {
    // let openUrl = `https://olympic001.com/api/game/games/link/honor?uid=${list.uid}&vendor=${list.vendor}`
    // if(vendor == 'PragmaticPlay J'){
    //     openUrl = `https://olympic001.com/api/game/games/link/pragmatic?uid=${list.uid}`
    // }
    const {User, UserAuth} = useContext(UserContext)
    const [gameData, setGameData] = useState<getDataInterface>({uid: "", vendor: ""})
    const [debuggerCheck, setDebuggerCheck] = useState<boolean>(true)
    const [openUrl, setOpenUrl] = useState<string>("")

    const params = useParams();
    const [location] = useSearchParams();

    function detectDevTool(allow: number | undefined): void {
        if (!allow || isNaN(+allow)) allow = 100;
        const start = +new Date();
        // debugger;
        const end = +new Date();
        if (isNaN(start) || isNaN(end) || end - start > allow) {
            // 개발자 도구가 open 된 것을 감지했을 때 실행할 코드 삽입
            setDebuggerCheck(true)
            console.log('개발자 도구가 감지되었습니다.');
        }else{
            setDebuggerCheck(false)
        }
    }
    function getHonorLink(vendor: string){
        if(vendor){
            const vendorSum = slot_json && casino_json
            const vendorInfo = getVendorInfo(vendor, vendorSum);
            let responseAPIUrl = `/api/game/lobbies/link/honor?vendor=${gameData.vendor}&id=${vendorInfo.id}`
            if(gameData.vendor == 'evolution I') {
                responseAPIUrl = `/api/game/lobbies/link/oracle?vendor=${gameData.vendor}&id=${vendorInfo.id}`
            }
            api(responseAPIUrl)
                .then(res => res.json())
                .then((data: {link: string}) => {
                    if(data?.link){
                        setOpenUrl(data.link)
                    }
                })
                .catch((res: Response)=> {
                    ApiErrorHandler(res, ()=> {
                        window.close()
                    })
                })
        }
    }
    useEffect(()=> {
        detectDevTool(undefined)
    }, [])
    useEffect(() => {
        if(!debuggerCheck){
            setGameData({
                uid: location.get('uid') || "",
                vendor: location.get('vendor') || ""
            })
        }
    }, [debuggerCheck]);
    useEffect(()=> {
        let openUrl: string = ""
        if (IsDevMode()) {
            if(isMobile){
                openUrl = "https://secretmobile.mario001.com"
            }else{
                openUrl = "https://mario001.com"
            }
        }
        // console.log(openUrl)
        if(params.vendor == 'pragmatic'){
            openUrl += `/api/game/games/link/pragmatic?uid=${gameData.uid}`
            setOpenUrl(openUrl)
        }else{
            if(otherCasino.includes(gameData.vendor) || SlotVendorListTypeArr.includes(gameData.vendor)){
                // 슬롯, 로비 없는 카지노
                openUrl += `/api/game/games/link/honor?uid=${gameData.uid}&vendor=${gameData.vendor}`
                // test url
                // openUrl += `/api/game/games/link/test?uid=${gameData.uid}&vendor=${gameData.vendor}`
                setOpenUrl(openUrl)
            }else{
                // 로비 有
                getHonorLink(gameData.vendor)
            }
        }
        // setTimeout(()=> setOpenUrl(openUrl), 3000)

    }, [gameData])
    useEffect(() => {
        resizeViewportSet();
        window.addEventListener("resize", resizeViewportSet);

        return () => window.removeEventListener("resize", resizeViewportSet);
    }, []);
    return (
        <>
            {
                User && !UserAuth
                    ? <>
                        <p>로그아웃 되었습니다.</p>
                    </>
                    : openUrl
                        ? <iframe className={'iframe-game-window'}
                                  onLoad={resizeViewportSet}
                                  src={openUrl}
                                  frameBorder="0"></iframe>
                        : <>
                            <Player src={loading} loop autoplay></Player>
                            <p>게임을 불러오는 중 입니다...</p>
                        </>

            }

        </>
    );
}

function resizeViewportSet(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
