import { useEffect, useRef } from 'react'

import { useIsomorphicLayoutEffect } from 'usehooks-ts'

function useInterval(callback: () => void, delay: number | null, timeout: number = 0) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useIsomorphicLayoutEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        let id: NodeJS.Timeout;
        setTimeout(()=> {
            id = setInterval(() => savedCallback.current(), delay)
        }, timeout)


        return () => clearInterval(id)
    }, [delay])
}

export default useInterval