import React from 'react';

type alignTypes = "left" | "center" | "right"
function Pagination({align = "center", page, setPage, totalPages}:
                        {align?: alignTypes, page: number, setPage: Function, totalPages: number}) {
    const pageSize: number = 7;
    const totalPage: number = Math.round(totalPages / 10) || 1

    let startPage: number = page - Math.floor(pageSize / 2);
    let endPage: number = startPage+pageSize

    if(page < Math.round(pageSize / 2)){
        startPage = 1
        endPage = pageSize + 1;
    }else if(page + Math.floor(pageSize / 2) < totalPage){
        startPage = page - Math.floor(pageSize / 2)
    }else{
        if(page > totalPage - Math.floor(pageSize / 2)){
            startPage = totalPage - pageSize + 1
        }
        endPage = totalPage + 1
    }

    if(endPage > totalPage){
        endPage = totalPage
    }

    const pageList: number[] = []
    for(let i=startPage; i<=endPage; i++){
        pageList.push(i)
    }

    return (
        <div className={`pagination ${align}`}>
            <ul>
                {
                    pageList.map((list, index)=> {
                        const returnPage = list
                        return (
                            <li key={index}>
                                <a href="#"
                                   className={page == list ? "on" : ""}
                                   onClick={e=> setPage(returnPage)}
                                >{returnPage}</a>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
}

export default Pagination;
