import Paper, {PaperProps} from "@mui/material/Paper";
import Draggable from "react-draggable";
import React, {useEffect} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {dialogSizeType} from "../../apps/_root";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function DialogAlert({open, setOpen, message="", size}:
                         {open: boolean, setOpen: Function, message?: React.ReactNode, size: dialogSizeType}){

    function handleClose(){
        setOpen(false)
    }

    document.onkeyup = (e: KeyboardEvent)=> {
        if(open && e.key == 'Enter'){
            handleClose()
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            className={size}
        >
            <DialogTitle
                id="draggable-dialog-title"
                style={{ cursor: 'move' }} >
                <header className={'dialog-header'}>알림</header>
            </DialogTitle>
            <DialogContent>
                <div className={'dialog-message'}>{message}</div>
            </DialogContent>
            <DialogActions>
                <footer className={'dialog-footer'}>
                    {/*<button autoFocus onClick={handleClose}>*/}
                    {/*    Cancel*/}
                    {/*</button>*/}
                    <button className={'dialog-ok'} onClick={handleClose}>OK</button>
                </footer>
            </DialogActions>
        </Dialog>
    )
}