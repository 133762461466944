import getCookie from "./getCookie"
import swal from "sweetalert"

export default async function api(input: RequestInfo | URL, init?: RequestInit) {
    const method = init?.method || 'GET'

    // CSRF 토큰이 필요한 메소드
    if (["POST", "PATCH", "PUT", "DELETE"].includes(method)) {
        let csrftoken = getCookie('csrftoken');
        if (!csrftoken) {
            await fetch('/api/csrf-token');
            csrftoken = getCookie('csrftoken');
        }
        return fetch(input, init && { ...init, headers: { ...init.headers, 'X-CSRFToken': csrftoken! } })
            .then(async res => {
                if (res.ok) {
                    return res
                } else {
                    throw res
                }
            })
    }
    // CSRF 토큰이 필요없는 메소드
    else {
        return fetch(input, init && { ...init, headers: { ...init.headers } })
            .then(async res => {
                if (res.ok) {
                    return res
                } else {
                    throw res
                }
            })
    }
}

export async function ApiErrorHandler(res: Response, callBack?: ()=> void) {
    switch (res.status) {
        case 400: // Forbidden 에러는 권한이 없을 때 발생한다.
            const err = await res.json();
            swal({
                text: err.error,
                icon: "error",
            }).then(function(){
                if(callBack){
                    callBack()
                }
            })
            break;
        default:
            if (res.headers.get('Content-Type') == 'application/json') {
                const errorMessage = await res.json()
                swal({
                    text: errorMessage,
                    icon: "error",
                });
            } else {
                swal({
                    text: "에러가 발생하였습니다.",
                    icon: "error",
                });
            }
    }
}