import React from 'react';
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";


function ModalContainer({show, setShow, title, children, closeEvent, className, closeBtn, prevBtn, centered}:
                            {
                                show: boolean;
                                setShow: any;
                                title: React.ReactNode;
                                children: React.ReactNode;
                                closeEvent: any;
                                className: string;
                                closeBtn: boolean;
                                prevBtn: boolean,
                                centered?: boolean,
                            }){
    function handleClose(){
        setShow(false)
        if(closeEvent){
            closeEvent()
        }
    }
    return (
        <Modal show={show}
               onHide={handleClose}
               scrollable={true}
               className={`page-modal ${className}`}
        >
            {
                closeBtn
                    ? <Modal.Header
                        className={'modal-close-btn'}
                        onClick={handleClose}
                    >
                        <FontAwesomeIcon icon={faXmark}/>
                      </Modal.Header>
                    : <></>
            }

            <Modal.Body>
                <div className={'modal-layout'}>
                    { title
                        ? <Modal.Header className={"modal-title"}>
                            <div className={'title-inner'}>{title}</div>
                        </Modal.Header>
                        : <></>
                    }
                    {children}
                </div>
            </Modal.Body>
        </Modal>
    )
}
ModalContainer.defaultProps = {
    title: "",
    closeEvent: undefined,
    className: "",
    closeBtn: true,
    prevBtn: false,
    centered: false,
}

export default ModalContainer;
