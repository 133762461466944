import React, {createContext, ReactNode, useEffect, useState} from 'react';
import useUser, {PrivateUser, PrivateUserDefault} from "../hooks/useUser";
import api from "../util/api";
import RegisterModal from "../component/user/register_modal";
import LoginModal from "../component/user/login_modal";
import DialogAlert from "../component/_common/_dialog_alert";
import { Outlet } from 'react-router-dom';

// Device control
interface deviceWidthInterface {
    deviceWidth: number,
    scroll: boolean
}
export const DeviceWidthContext = createContext<deviceWidthInterface>({
    deviceWidth: window.innerWidth,
    scroll: false
})

// detect login
interface UserContextInterface {
    User: PrivateUser
    UserAuth: boolean
    UserAuthCheck: (callback: ()=> void) => void
    MutateUser: () => void
    Logout: () => void
    LoginFailed: () => void
    SetLoginShow: () => void
    SetRegisterShow: () => void
}
export const UserContext = createContext<UserContextInterface>({
    User: PrivateUserDefault,
    UserAuth: false,
    UserAuthCheck: () => {},
    MutateUser: () => {},
    Logout: () => {},
    LoginFailed: () => {},
    SetLoginShow: () => {},
    SetRegisterShow: () => {}
})
export type dialogSizeType = "small" | "medium" | "large"
// alert dialog
interface dialogSetInterface {
    dialogSet: (set:
                    {
                        set:boolean,
                        message:ReactNode,
                        size?:dialogSizeType,
                    })=> void
}
export const DialogSetContext = createContext<dialogSetInterface>({
    dialogSet: ()=> {}
})


export default function RootLayout() {
    
    //device check
    const userAgent = navigator.userAgent;
    if (userAgent.match(/Android/i)) {
        document.body.setAttribute('data-device-type', 'Android')
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        document.body.setAttribute('data-device-type', 'IOS')
    } else {
        document.body.setAttribute('data-device-type', 'PC-Desktop')
    }
    let vh = 0;

    // ** window focus/blur event
    let browserVisibleState: boolean = true;
    let list: any[] = []

    function onFocusHandler(){
        list.some((id, key)=> clearInterval(id))
        list = []
        // console.log(browserVisibleState)
        if(!browserVisibleState){
            window.location.reload()
        }
    }
    function onBlurHandler(){
        const intervalId: NodeJS.Timeout = setTimeout(()=> {
            // window.location.reload();
            browserVisibleState = false
        }, 1000 * 60 * 60) // 1시간
        list[0] = (intervalId)
    }    
    useEffect(()=> {
        if(document.hasFocus()){
            onFocusHandler()
        }else{
            onBlurHandler()
        }
    }, [])
    useEffect(() => {
        window.addEventListener('blur', onBlurHandler)
        window.addEventListener('focus', onFocusHandler)

        return () => { 
            window.removeEventListener('blur', onBlurHandler)
            window.removeEventListener('focus', onFocusHandler)
        }
    }, []);
    // ** //

    useEffect(() => {
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []);

    const {user, mutateUser} = useUser()

    // user ************************************************
    const [User, setUser] = useState<PrivateUser>(user as PrivateUser)
    const [UserAuth, setUserAuth] = useState<boolean>(false)
    const [loginShow, setLoginShow] = useState(false)
    const [registerShow, setRegisterShow] = useState(false)

    function MutateUser(){
        mutateUser()
    }
    function LoginFailed(){
        SetLoginShow()
        // setDialogMessage("로그인이 필요한 서비스 입니다.")
        // setDialogOpen(true)
    }
    function SetLoginShow(){
        setLoginShow(true)
    }
    function SetRegisterShow(){
        setRegisterShow(true)
    }
    function Logout(){
        api(`/api/user/logout`)
            .then(e => mutateUser(undefined))
            .catch(e => alert('로그아웃 실패'))
    }
    function UserAuthCheck(callback: () => void){
        // callback()
        if(UserAuth){
            callback()
        }else{
            LoginFailed()
        }
    }
    useEffect(()=> {
        setUser(user as PrivateUser)
        setUserAuth(user?.is_authenticated || false)
    }, [user])


    // detect scroll, device ********************************************
    const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth)
    const [scroll, setScroll] = useState<boolean>(false)
    window.onresize = ()=> {
        setDeviceWidth(window.innerWidth)
    }
    window.onscroll = ()=> {
        scrollDetect()
    }
    function scrollDetect(){
        if(window.scrollY > 70){
            setScroll(true)
        }else{
            setScroll(false)
        }
    }


    // alert dialog ************************************************
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [dialogMessage, setDialogMessage] = useState<ReactNode>("")
    const [dialogSize, setDialogSize] = useState<dialogSizeType>("small")

    function dialogSet({set, message, size="small"}:
                           {
                               set:boolean,
                               message:ReactNode,
                               size?:dialogSizeType,
                           }){
        setDialogMessage(message)
        setDialogOpen(set)
        setDialogSize(size)
    }

    // set provider Value ***********************************************************
    const DeviceWidthContextValue = {
        deviceWidth,
        scroll
    }
    const UserContextValue = {
        User,
        UserAuth,
        UserAuthCheck,
        MutateUser,
        LoginFailed,
        Logout,
        SetRegisterShow,
        SetLoginShow
    }
    const DialogSetContextValue = {
        dialogSet
    }
    return (
            <DeviceWidthContext.Provider   value={DeviceWidthContextValue}>
                <div className={`container ${scroll ? "scrHead" : ""}`}>
                    <DialogSetContext.Provider value={DialogSetContextValue}>
                        <UserContext.Provider  value={UserContextValue}>

                            <Outlet/>

                            <RegisterModal registerShow={registerShow}
                                           setRegisterShow={setRegisterShow} />
                            <LoginModal loginShow={loginShow}
                                        setLoginShow={setLoginShow} />
                            <DialogAlert open={dialogOpen}
                                         setOpen={setDialogOpen}
                                         message={dialogMessage}
                                         size={dialogSize} />
                        </UserContext.Provider>
                    </DialogSetContext.Provider>
                </div>
            </DeviceWidthContext.Provider>
    );
}

