import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {Player} from "@lottiefiles/react-lottie-player";
import ready_mario from "../../_asset/lottie/loading.json"

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
function ReplayModalIframe({link, replayShow, SetReplayShow}:
                               {
                                   link: string,
                                   replayShow: boolean,
                                   SetReplayShow: Function
                               }) {
    const [loadingState, SetLoadingState] = useState<boolean>(true)
    const iframeRef = useRef<HTMLIFrameElement>(null)
    function handleClose(){
        SetReplayShow(false)
    }
    function iframeOnLoad(){
        SetLoadingState(false)
    }

    return (
        <Modal show={replayShow}
               onHide={handleClose}
               scrollable={true}
               className={`page-modal replay-popup`}
        >
            <span className={'modal-close-btn'}
                  onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmark}/>
            </span>
            <Modal.Body>
                {
                    loadingState
                        ? <div className={'iframe-loading-animation'}>
                            <Player src={ready_mario}
                                    loop
                                    autoplay
                                    style={{width: "30rem", height: "30rem"}}/>
                        </div>
                        : <></>
                }
                <iframe className={'lobby-honor-link-iframe'}
                        onLoad={iframeOnLoad}
                        src={link}
                        frameBorder={0}></iframe>
            </Modal.Body>
        </Modal>
    );
}

export default ReplayModalIframe;