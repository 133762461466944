import React, {useContext, useRef, useState} from 'react';
import {UserContext} from "../../apps/_root";
import ModalContainer from "../_common/_Modal_layout";
import Logo from "../_common/Logo";
import mario_login from "../../_asset/image/etc/login_mario.webp";
import api from "../../util/api";
import {PrivateUser} from "../../hooks/useUser";
import Btn from "../_custom/button";
import swal from "sweetalert"
import { isMobile } from 'react-device-detect';

export default function LoginModal({loginShow, setLoginShow}: {loginShow: boolean, setLoginShow: Function}) {
    const { MutateUser, SetRegisterShow } = useContext(UserContext);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null)

    function formSubmit(){
        if(formRef.current){
            setSubmitting(true);
            const formData = new FormData(formRef.current as HTMLFormElement);
            api('/api/user/login', { method: "POST", body: formData })
                .then(res => res.json())
                .then((data: PrivateUser) => {
                    setSubmitting(false);
                    MutateUser()
                    setLoginShow(false)
                    if(isMobile){
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    handleAPIError(error)
                })
        }
    }
    async function handleAPIError(res: Response) {
        setSubmitting(false);
        const err: any = await res.json();
        swal({
            text: err,
            icon: "error"
        })
    }
    return (
        <ModalContainer show={loginShow}
                        setShow={setLoginShow}
                        className={'login-pop'}
        >
            <aside>
                {/*<Logo/>*/}
                <img src={mario_login} alt="mario login" className={'login-mario'}/>
            </aside>
            <main>
                <div className={'inner-layout'}>
                    <header>
                        <p>로그인</p>
                        <p>Welcome to Mario Casino!</p>
                    </header>
                    <form action="/api/user/login"
                          onKeyUpCapture={e=> {
                              if(e.keyCode == 13){
                                  formSubmit()
                              }
                          }}
                          ref={formRef}>
                        <div className={"form-row"}>
                            <input type="text"
                                   name={'username'}
                                   placeholder={`아이디`}
                                   autoCapitalize={"off"}/>
                        </div>
                        <div className={"form-row"}>
                            <input type="password" name={'password'} placeholder={`비밀번호`}/>
                        </div>

                        <div className={'btn-area'}>
                            <Btn color={"yellow"} onClick={e=> {
                                e.preventDefault();
                                formSubmit()
                            }}>로그인</Btn>
                            <Btn color={"orange"}
                                 onClick={e=> {
                                     setLoginShow(false)
                                     SetRegisterShow()
                                 }}
                            >회원가입</Btn>
                        </div>
                    </form>
                </div>
            </main>
        </ModalContainer>
    );
}
