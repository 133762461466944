import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// banner image
import layer_00 from "../../_asset/image/banner2/Banner_Mario_layer_00.png"
import layer_01 from "../../_asset/image/banner2/Banner_Mario_layer_01.png"
import layer_02 from "../../_asset/image/banner2/Banner_Mario_layer_02.png"
import layer_03 from "../../_asset/image/banner2/Banner_Mario_layer_03.png"
import layer_04 from "../../_asset/image/banner2/Banner_Mario_layer_04.png"
import layer_05 from "../../_asset/image/banner2/Banner_Mario_layer_05.png"
import adbannerImage from "../../_asset/banner/main.jpg"

import { Autoplay, Navigation, Pagination } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from '../../apps/_root';
import { PageModalContext } from '../../apps/providers';
import { sexyVendor } from '../../data/gameData_casino';

export default function Banner() {
    return (
        <div className={'banner'}>
            <AdBannerLayout />
            <div className='ad-animation'>
                <BannerImages />
                <BannerSwiper />
            </div>
        </div>
    );
}

function AdBannerLayout() {
    const { UserAuthCheck } = useContext(UserContext)
    const { SetMenuType, SetVendor, SetPageName, SetPageModalShow } = useContext(PageModalContext)
    
    return (
        <div className='ad-banner --pointer'
            onClick={e =>
                UserAuthCheck(() => {
                    if(sexyVendor){
                        SetMenuType('vendor')
                        SetPageName(sexyVendor.name)
                        SetVendor(sexyVendor.vendor)
                        SetPageModalShow(true)
                    }
                })
            }
            >
            <img src={adbannerImage} alt="" />
        </div>
    )
}
function BannerImages() {
    return (
        <div className={'banner-image'}>
            <img src={layer_00} className={'bg_red'} />
            <img src={layer_01} className={'bg_gradation'} />
            <img src={layer_02} className={'bg_img'} />
            <div className={'fa_cover'}>
                <img src={layer_03} className={'family'} />
            </div>
            <div className={'coin_cover'}>
                <img src={layer_04} className={'coins'} />
            </div>
            <div className={'mario'}>
                <img src={layer_05} className={'mario_image'} />
            </div>
        </div>
    )
}

function BannerSwiper() {
    return (
        <div className={'banner-swiper-layout'}>
            <Swiper
                className={'banner-swiper'}
                direction={'vertical'}
                slidesPerView={1}
                loop={true}
                loopedSlides={1}

                speed={600}
                autoplay={{
                    delay: 5000
                }}
                pagination={{
                    clickable: true,
                    el: ".custom-pagination"
                }}
                navigation={{
                    enabled: true,
                    nextEl: ".swiper-arrow-next",
                    prevEl: ".swiper-arrow-prev",
                }}
                modules={[Autoplay, Pagination, Navigation]}
            >

                <SwiperSlide>
                    <div className={'banner-text-layout'}>
                        <div className={'inner'}>
                            <p className={'--color-beige'}>라이브 카지노 슬롯머신</p>
                            <p>해외정식 100% 정품 라이센스</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={'banner-text-layout'}>
                        <div className={'inner'}>
                            <p className={'--color-beige'}>실전과 같은 짜릿함!</p>
                            <p>라이브 카지노</p>
                            <p className={'--color-yellow'}>MARIO CASINO</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={'banner-text-layout'}>
                        <div className={'inner'}>
                            <p className={'--color-beige'}>국내 최대규모 마리오!</p>
                            <p>슬롯당첨금액 최대 <b>5천만원!</b></p>
                            <p><b>잭팟</b>의 주인공에 도전하세요!</p>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className={'swiper-custom-navigation'}>
                <div className={'swiper-arrow-prev'}>
                    <FontAwesomeIcon icon={faChevronUp} />
                </div>
                <div className={'custom-pagination'}></div>
                <div className={'swiper-arrow-next'}>
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
            </div>
        </div>
    )
}

function BannerItems() {
    return (
        <>
        </>
    )
}