import React, {useContext, useEffect, useState} from 'react';
import Pagination from "../_common/_pagination";
import Btn from "../_custom/button";
import api from "../../util/api";
import {NoticeModalContext} from "../../apps/providers";

export interface noticeListInterface {
    id: number,
    title: string,
    content: string,
    written_at: string,
    prevId: number,
    nextId: number,
}
export interface noticeApiInterface {
    total: number,
    notices: noticeListInterface[]
}
function setPrevNextId(list: noticeListInterface[]){
    let i: number = 0;
    for(i; i<list.length; i++){
        list[i].prevId = 0
        list[i].nextId = 0

        if(i == 0){
            list[i].nextId = list[i + 1].id
        }else if(i < list.length - 1){
            list[i].prevId = list[i - 1].id
            list[i].nextId = list[i + 1].id
        }else{
            list[i].prevId = list[i - 1].id
        }
    }

    return list
}

export default function NoticeList() {
    const {ReadIdOpen, SetReadIdOpen} = useContext(NoticeModalContext)
    const [page, setPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number>(1)
    const [readId, setReadId] = useState<number>(ReadIdOpen)

    const [noticeList, setNoticeList] = useState<noticeListInterface[]>()
    const [noticeDetail, setNoticeDetail] = useState<noticeListInterface | undefined>(undefined)

    function getNoticeList(){
        api(`/api/site/notices/?page=${page}`)
            .then(res=> res.json())
            .then((data: noticeApiInterface) => {

                setTotalPages(data.total)
                setNoticeList(setPrevNextId(data.notices))
            })
    }
    useEffect(()=> {
        getNoticeList()
    }, [page])
    useEffect(()=> {
        getNoticeList()

        return ()=> {
            SetReadIdOpen(0)
        }
    }, [])
    useEffect(()=> {
        if(readId && noticeList){
            for(let obj of noticeList){
                if(obj.id == readId){
                    setNoticeDetail(obj)
                }
            }
        }
    },[readId, noticeList])

    return (
        <div className={'modal-notice-layout'}>
            <header className={'notice-row head'}>
                <div>번호</div>
                <div>내용</div>
            </header>
            <main>
                <ul>
                    {
                        noticeList?.map((notice, index)=>(
                            <NoticeItem key={index}
                                        notice={notice}
                                        readId={readId}
                                        setNoticeDetail={setNoticeDetail}
                                        setReadId={setReadId} />
                        ))
                    }
                </ul>

                {
                    readId && noticeDetail
                        ? <NoticeView detail={noticeDetail}
                                      setReadId={setReadId}/>
                        : <Pagination page={1}
                                      setPage={setPage}
                                      totalPages={totalPages}/>
                }
            </main>
        </div>
    );
}


type itemStateType = "select" | "unSelect" | "default"
function NoticeItem({notice, readId, setReadId, setNoticeDetail}:
                        {
                            notice: noticeListInterface;
                            readId: number;
                            setReadId: Function;
                            setNoticeDetail: Function;
                        }){
    const [isSelect, setIsSelect] = useState<itemStateType>("default")

    useEffect(()=> {
        if(readId == 0){
            setIsSelect('default')
        }else if(notice.id == readId){
            setIsSelect('select')
        }else{
            setIsSelect('unSelect')
        }
    }, [readId])
    return (
        <li className={`notice-row item ${isSelect}`}
            onClick={e=> {
                if(isSelect == 'select'){
                    setReadId(0)
                    setNoticeDetail(undefined)
                }else{
                    setReadId(notice.id)
                    setNoticeDetail(notice)
                }
            }}
        >
            <div>{notice.id}</div>
            <div>{notice.title}</div>
        </li>
    )
}

function NoticeView({detail, setReadId}: {detail: noticeListInterface; setReadId: Function}){
    return (
        <div className={'notice-view-layout'}>
            <div className={'notice-content'}>
                <div className={'contents'} dangerouslySetInnerHTML={{__html: detail.content}}></div>
            </div>
            <div className={'notice-view-btn-area'}>
                <Btn color={"orange"}
                     onClick={e=> setReadId(0)}
                    >목록가기</Btn>
                <Btn color={"gray"}
                     className={ detail.prevId ? "" : "disabled-btn" }
                     onClick={e=> setReadId(detail.prevId)} >이전글</Btn>
                <Btn color={"gray"}
                     className={ detail.nextId ? "" : "disabled-btn" }
                     onClick={e=> setReadId(detail.nextId)} >다음글</Btn>
            </div>
        </div>
    )
}