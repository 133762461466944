import React, {ReactNode} from "react";

export function FormRow({children}: {children: ReactNode}){
    return <div className={'form-row'}>{children}</div>
}
FormRow.Name = ({children}: {children: ReactNode})=> {
    return <p className={'name'}>{children}</p>
}
interface CustomInputInterface extends React.InputHTMLAttributes<HTMLInputElement> {}
FormRow.Input = React.forwardRef<HTMLInputElement, CustomInputInterface>(
    (props, ref)=> {
        return <input { ...props }/>
    }
)
interface CustomSelectInterface extends React.SelectHTMLAttributes<HTMLSelectElement> {}
FormRow.Select = React.forwardRef<HTMLInputElement, CustomSelectInterface>(
    (props, ref)=> {
        return <select { ...props }/>
    }
)