import React, {createContext, ReactNode, useEffect, useState} from 'react';
import PageModal, {modalPageMenuType} from "../component/page/page_modal";


// page modal
interface PageModalInterface {
    SetPageModalShow: (show: boolean)=> void
    SetMenuType: (menutype: modalPageMenuType)=> void
    SetPageName: (pageName: string)=> void
    SetVendor: (vendor: string)=> void
}
export const PageModalContext = createContext<PageModalInterface>({
    SetPageModalShow: ()=> {},
    SetMenuType: ()=> {},
    SetPageName: ()=> {},
    SetVendor: ()=> {},
})

interface NoticeModalInterface {
    ReadIdOpen: number,
    SetReadIdOpen: (readIdOpen: number)=> void,
    SetNoticeModalOpen: (readId: number)=> void
}
export const NoticeModalContext = createContext<NoticeModalInterface>({
    ReadIdOpen: 0,
    SetReadIdOpen: ()=> {},
    SetNoticeModalOpen: ()=> {}
})

function Providers({children}: {children: ReactNode}) {


    // page modal ***********************************************************
    const [pageModalShow, setPageModalShow] = useState(false)
    const [menuType, setMenuType] = useState<modalPageMenuType>('game')
    const [pageName, setPageName] = useState<string>('로딩중...')
    const [vendor, setVendor] = useState<string>('')

    function SetPageModalShow(show: boolean){
        setPageModalShow(show)
    }
    function SetMenuType(menuType: modalPageMenuType){
        setMenuType(menuType)
    }
    function SetPageName(pageName: string){
        setPageName(pageName)
    }
    function SetVendor(vendor: string){
        setVendor(vendor)
    }

    // notice modal ************************************************************
    const [ReadIdOpen, setReadIdOpen] = useState<number>(0)
    function SetReadIdOpen(readIdOpen: number){
        setReadIdOpen(readIdOpen)
    }
    function SetNoticeModalOpen(readId: number){
        SetReadIdOpen(readId)
        SetPageModalShow(true)
        SetMenuType('board')
        SetPageName('공지사항')
    }

    const PageModalContextValue = {
        SetPageModalShow,
        SetMenuType,
        SetPageName,
        SetVendor
    }
    const NoticeModalContextValue = {
        ReadIdOpen,
        SetReadIdOpen,
        SetNoticeModalOpen
    }
    return (
        <PageModalContext.Provider value={PageModalContextValue}>
            <NoticeModalContext.Provider value={NoticeModalContextValue}>
                {children}
                <PageModal pageModalShow={pageModalShow}
                           setPageModalShow={setPageModalShow}
                           menuType={menuType}
                           pageName={pageName}
                           vendor={vendor} />
            </NoticeModalContext.Provider>
        </PageModalContext.Provider>
    );
}

export default Providers;