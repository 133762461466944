import React, {useEffect, useRef, useState} from 'react';
import companyHover from "../../_asset/audio/companyHover.mp3"

type audioPlayerType = "company"
function AudioPlayer({type, play}: {type: audioPlayerType, play: boolean}) {
    return (
        <CompanyPlayer play={play}/>
    );
}

export default AudioPlayer;

function CompanyPlayer({play}: {play: boolean}){
    const [playState, setPlayState] = useState<boolean>(false)
    const audioPlay = useRef<HTMLAudioElement>(null)

    useEffect(()=> {
        if(audioPlay.current && play){
            audioPlay.current.volume = 0.3;
            const audio_ = audioPlay.current.play()
            audio_.then(()=> {

            }).catch((e)=> {
                console.log(e)
            })
        }
    }, [play])
    return (
        <audio ref={audioPlay}
               src={companyHover}
               autoPlay={playState}
               onEnded={e=> setPlayState(false)}
        ></audio>
    )
}